import {LocalStorageManager} from "../LocalStorageManager";
import i18next from "i18next";

interface ConfigurationState {
    language: string
}

enum ConfigurationsActionType {
    SET_LANGUAGE = "configurations/SET_LANGUAGE"
}

interface SetLanguageAction {
    type: ConfigurationsActionType.SET_LANGUAGE
    language: string
}

type ConfigurationsAction = SetLanguageAction

const initialState: ConfigurationState = {
    language: LocalStorageManager.getLanguage(),
};


export class Configurations {
    static setLanguage(language: string) {
        LocalStorageManager.setLanguage(language)
        i18next.changeLanguage(language)
        return {
            type: ConfigurationsActionType.SET_LANGUAGE,
            language: language
        };
    }
}

function configurationsReducer(
    state: ConfigurationState = initialState,
    action: ConfigurationsAction,
): ConfigurationState {
    switch (action.type) {
        case ConfigurationsActionType.SET_LANGUAGE:
            return {
                language: action.language,
            };
        default:
            return state;
    }
}

export default configurationsReducer;