import styled from "styled-components";
import Text from "../Text";

/**
 * @author jaeho.lee104 on 2023. 08. 08..
 */
const NewP3 = styled(Text)`
  text-align: center;
  font-feature-settings: 'clig' off, 'liga';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`;

export default NewP3;