import {useLocation} from "react-router-dom";
import React, {useLayoutEffect} from "react";

const ScrollToTop = () => {
    const {pathname} = useLocation();
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
        document.getElementById("app-root")
            ?.scrollTo(0, 0);
    }, [pathname]);
    return null;
};

export default ScrollToTop;