/**
 * @author jaeho.lee104 on 2023. 05. 05..
 */
import * as Sentry from "@sentry/react";
import {Scope} from "@sentry/types/types/scope";

export class Logger {
    static debug(componentName: string, message: string, onScope?: (scope: Scope) => void) {
        Sentry.captureMessage(message, scope => {
            scope.setLevel("debug")
            scope.setExtra("componentName", componentName)
            if (onScope) {
                onScope(scope)
            }
            return scope
        })
    }

    static info(componentName: string, message: string, onScope?: (scope: Scope) => void) {
        Sentry.captureMessage(message, scope => {
            scope.setLevel("info")
            scope.setExtra("componentName", componentName)
            if (onScope) {
                onScope(scope)
            }
            return scope
        })
    }

    static error(componentName: string, message: string, onScope?: (scope: Scope) => void) {
        Sentry.captureMessage(message, scope => {
            scope.setLevel("error")
            scope.setExtra("componentName", componentName)
            if (onScope) {
                onScope(scope)
            }
            return scope
        })
    }

    static fatal(componentName: string, message: string, onScope?: (scope: Scope) => void) {
        Sentry.captureMessage(message, scope => {
            scope.setLevel("fatal")
            scope.setExtra("componentName", componentName)
            if (onScope) {
                onScope(scope)
            }
            return scope
        })
    }

    static captureEvent(event: Sentry.Event, captureContext?: ((scope: Scope) => Scope)) {
        Sentry.captureEvent(event, {
            captureContext: captureContext
        })
    }

    static captureException(exception: any, captureContext?: ((scope: Scope) => Scope)) {
        Sentry.captureException(exception, captureContext)
    }

    static setUserId(userId: string | null) {
        const user = userId ? {id: userId} : null
        Sentry.setUser(user)
    }
}