import {KeyboardUtils} from "./KeyboardUtils";

export class FormatUtils {
    static toCurrencyFormat(amount: string): string {
        if (amount.length < 0) {
            return "";
        }
        let result = "";
        let decimalIndex = amount.indexOf(".")
        let decimalValue = decimalIndex === -1 ? "" : amount.substring(decimalIndex)
        let intValue = decimalIndex === -1 ? amount : amount.substring(0, decimalIndex)
        let length = intValue.length;
        for (let i = length - 1, j = 0; i >= 0; i--, j++) {
            if (j > 0 && j % 3 === 0) {
                result += ",";
            }
            result += intValue[i];
        }
        const reverse = result.split("")
            .reverse()
            .join("")
        return reverse + decimalValue;
    }

    static toHsCodeFormat(hsCode?: string, maxLength = 6): string {
        if (!hsCode) {
            return ""
        }
        if (isNaN(Number(hsCode))) {
            return hsCode
        }
        if (hsCode.length <= 4) {
            return hsCode
        } else {
            const clearNumber = KeyboardUtils.clearNumber(hsCode)
            if (maxLength > 6) {
                if (hsCode.length <= 6) {
                    return `${clearNumber.slice(0, 4)}.${clearNumber.slice(4, maxLength)}`
                } else {
                    return `${clearNumber.slice(0, 4)}.${clearNumber.slice(4, 6)}-${clearNumber.slice(6, maxLength)}`
                }
            } else {
                return `${clearNumber.slice(0, 4)}.${clearNumber.slice(4, maxLength)}`
            }
        }
    }
}