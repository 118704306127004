import {useLocation} from "react-router-dom";
import TermsOfServiceKrFragment from "./fragments/TermsOfServiceKrFragment";
import TermsOfServiceEnFragment from "./fragments/TermsOfServiceEnFragment";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "./modules";
import PrivacyPolicyKrFragment from "./fragments/PrivacyPolicyKrFragment";
import PrivacyPolicyEnFragment from "./fragments/PrivacyPolicyEnFragment";

const MultiLanguageGateway = () => {
    const location = useLocation()
    const {language} = useSelector((state: RootState) => state.configurationsReducer, shallowEqual);
    if (location.pathname === '/terms-of-service') {
        if (language === 'en') {
            return <TermsOfServiceEnFragment/>
        } else {
            return <TermsOfServiceKrFragment/>
        }
    } else if (location.pathname === '/privacy-policy') {
        if (language === 'en') {
            return <PrivacyPolicyEnFragment/>
        } else {
            return <PrivacyPolicyKrFragment/>
        }
    } else {
        return <></>
    }
}

export default MultiLanguageGateway