import React from "react";
import {ContentContainer} from "../styledcomponents/Containers";
import styled from "styled-components";
import {ColumnsType} from "antd/es/table";
import {Table} from "antd";

/**
 * @author jaeho.lee104 on 2023. 04. 04..
 */
const PrivacyPolicyKrFragment = () => {

    const Li = styled.li`
      margin-top: 6px;
      font-size: 16px;
      line-height: 1.7;
    `
    const P = styled.p`
      font-size: 16px;
      line-height: 1.7;
    `

    const H5 = styled.h5`
      font-size: 20px;
      margin-top: 40px;
    `

    interface DelegateTableDataType {
        delegator: string;
        job: string;
        expiredDate: string;
    }

    const delegateColumns: ColumnsType<DelegateTableDataType> = [
        {
            title: '위탁받는자(수탁자)',
            align: "center",
            dataIndex: 'delegator',
        },
        {
            title: '위탁업무',
            align: "center",
            dataIndex: 'job',
        },
        {
            title: '보유 및 처리기간',
            align: "center",
            dataIndex: 'expiredDate',
        }
    ];
    const delegateData: DelegateTableDataType[] = [
        {
            delegator: '토스페이먼츠',
            job: '전자 결제대행 및 요금정산 처리',
            expiredDate: "위탁계약 종료시",
        },
        {
            delegator: '(주)페이플',
            job: '전자 결제대행 및 요금정산 처리',
            expiredDate: "위탁계약 종료시",
        }
    ]

    interface CollectTableDataType {
        collectItem: string;
        collectMethod: string;
        collectPurpose: string;
        collectExpiredDate: string;
    }

    const collectTableColumns: ColumnsType<CollectTableDataType> = [
        {
            title: '수집하는 행태정보의 항목',
            align: "center",
            dataIndex: 'collectItem',
        },
        {
            title: '행태정보 수집 방법',
            align: "center",
            dataIndex: 'collectMethod',
        },
        {
            title: '행태정보 수집 목적',
            align: "center",
            dataIndex: 'collectPurpose',
        },
        {
            title: '보유·이용기간 및 이후 정보처리 방법',
            align: "center",
            dataIndex: 'collectExpiredDate',
        }
    ];
    const collectTableData: CollectTableDataType[] = [
        {
            collectItem: '이용자의 웹사이트/앱서비스 방문이력, 검색이력, 거래이력',
            collectMethod: '이용자의 웹사이트 및 앱 방문/실행시 자동수집',
            collectPurpose: "이용자의 관심,성향에 기반한 개인 맞춤형 상품·거래추천서비스(광고포함)을 제공",
            collectExpiredDate: "수집일로부터 1년 후 파기"
        },
    ]

    interface ManagerTableDataType {
        label: string;
        content: string
    }

    const managerTableColumns: ColumnsType<ManagerTableDataType> = [
        {
            title: '',
            align: "center",
            dataIndex: 'label',
        }, {
            title: '개인정보 보호책임자',
            align: "center",
            dataIndex: 'content',
        },
    ];
    const managerTableData: ManagerTableDataType[] = [
        {
            label: '부서명',
            content: '데이터관리팀',
        },
        {
            label: '담당자',
            content: '김민지',
        },
        {
            label: '연락처',
            content: '010-2476-9700',
        },
        {
            label: '이메일',
            content: 'business@thirdworks.co.kr',
        },
    ]

    return (
        <ContentContainer
            width={"100%"}
            maxWidth={"800px"}
            alignItems={"flex-start"}
            style={{textAlign: "start"}}
            paddingLeft={"20px"}
            paddingRight={"20px"}>
            <h3 style={{marginTop: "80px", alignSelf: "center"}}>THIRD WORKS 개인정보처리방침</h3>
            <P style={{marginTop: "60px"}}>
                주식회사 써드웍스(이하 “회사”라 한다)는 고객의 자유와 권리 보호를 위해 「개인정보 보호법」 및 관계 법령이 정한 바를 준수하여, 적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다. 이에 「개인정보 보호법」
                제30조에 따라 정보주체에게 개인정보 처리에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.
            </P>
            <H5>제1조 (개인정보의 처리 목적)</H5>
            <ol>
                <Li>회사는 대고객 서비스 제공, 민원처리 및 소관업무 수행 등의 목적으로 필요에 의한 최소한으로 개인정보를 처리하고 있습니다. 이용목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는
                    등 필요한 조치를 이행할 예정입니다.
                    <ol type={"a"}>
                        <Li>서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 이용요금 정산, 콘텐츠 제공, 본인 인증, 구매 및 요금 결제, 요금추심</Li>
                        <Li>회원 관리<br/>
                            회원제 서비스 이용에 따른 본인확인, 개인식별, 불량회원의 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인, 가입제한, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항
                            전달</Li>
                        <Li>마케팅 및 광고에 활용<br/>
                            신규 서비스 개발 및 특화, 인구통계학적 특성에 따른 서비스 제공 및 광고게재, 접속 빈도 파악, 회원의 서비스 이용에 대한 통계, 이벤트 등 광고성 정보 전달(회원님의 개인정보는 광고를 의뢰한
                            개인이나 단체에는 제공되지 않습니다.)</Li>
                    </ol>
                </Li>
            </ol>
            <H5>제2조 (개인정보의 처리 및 보유기간)</H5>
            <ol>
                <Li>회사에서 처리하는 개인정보는 수집·이용 목적으로 범위 내에서 처리하며, 개인정보 보호법 및 관련법령에서 정하는 보유기간을 준용합니다.</Li>
                <Li>회사의 홈페이지에서 처리하는 개인정보의 보유기간은 다음과 같습니다.
                    <ol type={"a"}>
                        <Li>회원탈퇴 시 삭제 개인정보
                            <ul>
                                <Li>삭제항목 : 회원님께서 제공한 이름, 이메일, 전화번호(휴대폰번호)</Li>
                                <Li>삭제기간 : 회원탈퇴 시 즉시</Li>
                            </ul>
                        </Li>
                        <Li>상거래 관련 보존 개인정보
                            <ul>
                                <Li>보존항목 : 상거래이력</Li>
                                <Li>보존근거 : 상법, 전자상거래 등에서의 소비자보호에 관한 법률</Li>
                                <Li>보존기간:
                                    <ul>
                                        <Li>계약 또는 청약철회 등에 관한 기록 : 5년</Li>
                                        <Li>대금결제 및 재화 등의 공급에 관한 기록 : 5년</Li>
                                        <Li>소비자의 불만 또는 분쟁처리에 관한 기록 : 3년</Li>
                                    </ul>
                                </Li>
                            </ul>
                        </Li>
                        <Li>정보시스템에 접속/이용한 기록
                            <ul>
                                <Li>보존항목 : 접속/이용 기록</Li>
                                <Li>보존기간 : 서비스 접속 및 이용 등에 관한 기록 : 5년</Li>
                            </ul>
                        </Li>
                    </ol>
                </Li>
            </ol>
            <H5>제3조 (처리하는 개인정보 항목)</H5>
            <ol>
                <Li>회사에서 처리하는 개인정보의 처리목적은 다음과 같습니다.
                    <ol type={"a"}>
                        <Li>회원가입 시 수집하는 개인정보 항목<br/>최초 회원 가입시 회원식별 및 최적화된 서비스 제공을 위해 아래와 같은 정보를 수집합니다.
                            <ul>
                                <Li>필수사항: 성명, 아이디, 회사명, 비밀번호, 이메일, 전화번호(휴대폰번호), 사업자번호</Li>
                            </ul>
                        </Li>
                        <Li>서비스 이용과정 시 등 수집되는 정보항목<br/>서비스 이용과정이나 사업 처리과정에서 아래와 같은 정보들이 생성되어 수집될 수 있습니다.
                            <ul>
                                <Li>서비스 이용기록, 접속로그, 쿠키, 접속IP 정보, 결제기록, 불량이용 기록</Li>
                            </ul>
                        </Li>
                        <Li>마케팅 및 광고 활용에 수집되는 개인정보 항목<br/>회사는 마케팅 및 광고 활용을 위해 아래와 같은 정보를 수집합니다.
                            <ul>
                                <Li>필수사항: 성명, 이메일, 전화번호(휴대폰번호)</Li>
                            </ul>
                        </Li>
                        <Li>수집방법<br/>회사는 다음과 같은 방법으로 개인정보를 수집합니다.
                            <ul>
                                <Li>홈페이지를 통한 회원가입, 양식, 전화, 상담 게시판, 이메일, 이벤트 응모, 배송요청</Li>
                                <Li>생성정보 수집 툴을 통한 수집</Li>
                            </ul>
                        </Li>
                    </ol>
                </Li>
            </ol>
            <H5>제4조 (개인정보의 제3자 제공)</H5>
            <ol>
                <Li>회사는 정보주체의 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 다음의 경우를 제외하고는 정보주체의 사전 동의 없이는 본래의 목적 범위를 초과하여 처리하거나 제3자에게 제공하지
                    않습니다.
                    <ol type={"a"}>
                        <Li>정보주체로부터 별도의 동의를 받는 경우</Li>
                        <Li>법률에 특별한 규정이 있는 경우</Li>
                        <Li>정보주체 또는 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여
                            필요하다고 인정되는 경우</Li>
                        <Li>개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공하지 아니하면 다른 법률에서 정하는 소관 업무를 수행할 수 없는 경우로서 보호위원회의 심의·의결을 거친 경우</Li>
                        <Li>조약, 그 밖의 국제협정의 이행을 위하여 외국정보 또는 국제기구에 제공하기 위하여 필요한 경우</Li>
                        <Li>범죄의 수사와 공소의 제기 및 유지를 위하여 필요한 경우</Li>
                        <Li>법원의 재판업무 수행을 위하여 필요한 경우</Li>
                        <Li>형 및 감호, 보호처분의 집행을 위하여 필요한 경우</Li>
                    </ol>
                </Li>
            </ol>
            <H5>제5조 개인정보처리의 위탁</H5>
            <ol>
                <Li>회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.
                    <Table columns={delegateColumns}
                           dataSource={delegateData}
                           pagination={false}
                           style={{marginTop: "20px", marginBottom: "20px"}}
                           bordered/>
                </Li>
                <Li>회사는 위탁계약 체결 시 「개인정보 보호법」 제26조에 따라 위탁업무 수행목적 외 개인정보 처리 금지, 기술적·관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리·감독, 손해배상 등 책임에 관한 사항을 계약서
                    등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.</Li>
                <Li>위탁업무의 내용이나 수탁자가 변경될 경우에는 지체 없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.</Li>
            </ol>
            <H5>제6조 (개인정보의 파기 절차 및 방법에 관한 사항)</H5>
            <ol>
                <Li>회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.</Li>
                <Li>정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관
                    장소를 달리하여 보존합니다.</Li>
                <Li>개인정보 파기의 절차 및 방법은 다음과 같습니다.
                    <ol type={"a"}>
                        <Li>파기절차
                            <br/>
                            불필요한 개인정보 및 개인정보파일은 개인정보 보호책임자의 책임 하에 내부방침 절차에 따라 다음과 같이 처리하고 있습니다.
                            <ul>
                                <Li>개인정보의 파기<br/>
                                    보유기간이 경과한 개인정보는 종료일로부터 지체 없이 파기합니다.</Li>
                                <Li>개인정보파일의 파기<br/>
                                    개인정보파일의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보파일이 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 지체 없이 그
                                    개인정보파일을 파기합니다.</Li>
                            </ul>
                        </Li>
                        <Li>파기방법
                            <ul>
                                <Li>전자적 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.</Li>
                                <Li>종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</Li>
                            </ul>
                        </Li>
                    </ol>
                </Li>
            </ol>
            <H5>제7조 (정보주체와 법정대리인의 권리·의무 및 행사방법에 관한 사항)</H5>
            <ol>
                <Li>이용자 및 법정대리인은 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.
                    <br/>※ 만 14세 미만 아동에 관한 개인정보의 열람등 요구는 법정대리인이 직접 해야 하며, 만 14세 이상의 미성년자인 이용자는 이용자의 개인정보에 관하여 미성년자 본인이 권리를 행사하거나 법정대리인을
                    통하여 권리를 행사할 수도 있습니다.</Li>
                <Li>제1항에 따른 권리 행사는 [개인정보 처리 방법에 관한 고시 별지 제8호] 서식에 따라 작성 후 서면, 전자우편, 모사 전송(FAX) 등을 통하여 하실 수 있으며, 이에 대해 지체 없이
                    조치하겠습니다.</Li>
                <Li>정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용 하거나 제공하지 않습니다.</Li>
                <Li>제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 [개인정보 처리 방법에 관한 고시 별지 제11호] 서식에 따른 위임장을 제출하셔야
                    합니다.</Li>
                <Li>개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제5항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.</Li>
                <Li>개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</Li>
                <Li>정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
                    <ul>
                        <Li>본인을 확인할 수 있는 신분증(주민등록증, 운전면허증, 여권 등)을 제시</Li>
                        <Li>대리인의 경우 대리인을 확인할 수 있는 신분증과 위임장 제시</Li>
                        <Li>[개인정보 처리 방법에 관한 고시 별지 제8호] 개인정보열람요구서 제시</Li>
                        <Li>[개인정보 처리 방법에 관한 고시 별지 제11호] 위임장 제시</Li>
                    </ul>
                </Li>
            </ol>
            <H5>제8조 (개인정보의 안전성 확보조치에 관한 사항)</H5>
            <P>회사의 웹서비스는 「개인정보보호법」 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적, 관리적, 물리적 조치를 하고 있습니다.</P>
            <ol>
                <Li>내부관리계획의 수립 및 시행
                    <br/>회사는 ‘개인정보의 안전성 확보조치 기준’에 의거하여 내부관리계획을 수립 및 시행하고 있습니다.</Li>
                <Li>개인정보 취급 담당자의 최소화 및 교육
                    <br/>개인정보를 취급하는 담당자를 지정하고 최소화하여 개인정보를 관리하는 대책을 시행하고 있습니다.</Li>
                <Li>개인정보에 대한 접근 제한
                    <br/>개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을
                    통제하고 있습니다.</Li>
                <Li>접속기록의 보관 및 위·변조 방지
                    <br/>개인정보처리시스템에 접속한 기록(웹 로그, 요약정보 등)을 최소 6개월 이상 보관·관리하고 있습니다.</Li>
                <Li>개인정보의 암호화
                    <br/>이용자의 개인정보는 암호화 되어 저장 및 관리되고 있습니다. 또한 중요한 데이터는 저장 및 전송 시 암호화하여 사용하는 등의 별도 보안기능을 사용하고 있습니다.</Li>
                <Li>보안프로그램 설치 및 주기적 점검·갱신
                    <br/>회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적으로 갱신·점검하고 있습니다.</Li>
            </ol>
            <H5>제9조 (개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항)</H5>
            <ol>
                <Li>회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다.</Li>
                <Li>쿠키는 웹사이트를 운영하는 이용되는 서버가 이용자의 컴퓨터 브라우저에 보내는 소량의 정보이며, 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.
                    <ol type={"a"}>
                        <Li>쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기검색어, 보안접속 여부 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.</Li>
                        <Li>쿠키의 설치·운영 및 거부: 웹브라우저의 상단의 '도구 &gt; 인터넷옵션 &gt; 개인정보' 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.</Li>
                        <Li>쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</Li>
                    </ol>
                </Li>
            </ol>
            <H5>제10조 (행태정보의 수집·이용·제공 및 거부 등에 관한 사항)</H5>
            <ol>
                <Li>회사는 서비스 이용과정에서 정보주체에게 최적화된 맞춤형 서비스 및 혜택, 온라인 맞춤형 광고 등을 제공하기 위하여 행태정보를 수집·이용하고 있습니다.</Li>
                <Li>회사는 다음과 같이 행태정보를 수집합니다.
                    <Table columns={collectTableColumns}
                           dataSource={collectTableData}
                           pagination={false}
                           style={{marginTop: "20px", marginBottom: "20px"}}
                           bordered/>
                </Li>
                <Li>회사는 온라인 맞춤형 광고 등에 필요한 최소한의 행태정보만을 수집하며, 사상, 신념, 가족 및 친인척관계, 학력·병력, 기타 사회활동 경력 등 개인의 권리·이익이나 사생활을 뚜렷하게 침해할 우려가 있는 민감한
                    행태정보를 수집하지 않습니다.</Li>
                <Li>정보주체는 웹브라우저의 쿠키 설정 변경 등을 통해 온라인 맞춤형 광고를 일괄적으로 차단·허용할 수 있습니다. 다만, 쿠키 설정 변경은 웹사이트 자동로그인 등 일부 서비스의 이용에 영향을 미칠 수
                    있습니다.
                    ▶ 웹브라우저를 통한 맞춤형 광고 차단/허용
                    <ol type={"a"}>
                        <Li>인터넷 익스플로러(Windows 10용 Internet Explorer 11)
                            <ul>
                                <Li>Internet Explorer에서 도구 버튼을 선택한 다음 인터넷 옵션을 선택</Li>
                                <Li>개인 정보 탭을 선택하고 설정에서 고급을 선택한 다음 쿠키의 차단 또는 허용을 선택</Li>
                            </ul>
                        </Li>
                        <Li>Microsoft Edge
                            <ul>
                                <Li>Edge에서 오른쪽 상단 ‘…’ 표시를 클릭한 후, 설정을 클릭합니다.</Li>
                                <Li>설정 페이지 좌측의 ‘개인정보, 검색 및 서비스’를 클릭 후 「추적방지」 섹션에서 ‘추적방지’ 여부 및 수준을 선택합니다.</Li>
                                <Li>‘InPrivate를 검색할 때 항상 "엄격" 추적 방지 사용’ 여부를 선택합니다.</Li>
                                <Li>아래 「개인정보」 섹션에서 ‘추적 안함 요청보내기’ 여부를 선택합니다.</Li>
                            </ul>
                        </Li>
                        <Li>크롬 브라우저
                            <ul>
                                <Li>Chrome에서 오른쪽 상단 ‘⋮’ 표시(chrome 맞춤설정 및 제어)를 클릭한 후, 설정 표시를 클릭합니다.</Li>
                                <Li>설정 페이지 하단에 ‘고급 설정 표시’를 클릭하고 「개인정보」 섹션에서 콘텐츠 설정을 클릭합니다.</Li>
                                <Li>쿠키 섹션에서 ‘타사 쿠키 및 사이트 데이터 차단’의 체크박스를 선택합니다.</Li>
                            </ul>
                        </Li>
                    </ol>
                </Li>
            </ol>
            <H5>제11조 (개인정보 보호책임자에 관한 사항)</H5>
            <ol>
                <Li>회사는 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 개인정보 보호책임자 및 분야별 개인정보 보호담당자를 지정하고 있습니다. (개인정보보호법 제31조 제1항에 따른 개인정보
                    보호책임자)
                    <Table columns={managerTableColumns}
                           dataSource={managerTableData}
                           pagination={false}
                           bordered/>
                </Li>
            </ol>
            <H5>제12조 (개인정보의 열람청구를 접수·처리하는 부서)</H5>
            <ol>
                <Li>정보주체는 개인정보보호법 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 회사는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
                    <ul>
                        <Li>개인정보 열람청구 접수·처리부서
                            <ul>
                                <Li>부서명 : 데이터관리팀</Li>
                                <Li>담당자 : 김민지</Li>
                                <Li>연락처 : 전화 010-2476-9700 / 이메일 business@thirdworks.co.kr</Li>
                            </ul>
                        </Li>
                    </ul>
                </Li>
                <Li>정보주체는 제1항의 열람청구 접수·처리부서 이외에, 개인정보보호위원회의 ‘개인정보보호 종합포털’ (www.privacy.go.kr) 를 통하여서도 개인정보 열람청구를 할 수 있습니다.
                    <ul>
                        <Li>개인정보보호위원회 개인정보보호 종합포털 → 개인정보 민원 → 개인정보 열람 등 요구(공공 아이핀을 통한 실명인증 필요)</Li>
                    </ul>
                </Li>
            </ol>
            <H5>제13조 (정보주체의 권익침해에 대한 구제방법)</H5>
            <ol>
                <Li>정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의할 수 있습니다. 아래의 기관은 회사와 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지
                    못하거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.</Li>
                <ul>
                    <Li>개인정보 침해신고센터 (한국인터넷진흥원 운영)
                        <ul>
                            <Li>소관업무 : 개인정보 침해사실 신고, 상담 신청</Li>
                            <Li>홈페이지 : privacy.kisa.or.kr</Li>
                            <Li>전화 : (국번없이) 118</Li>
                            <Li>주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층 개인정보침해 신고센터</Li>
                        </ul>
                    </Li>
                    <Li>개인정보 분쟁조정위원회
                        <ul>
                            <Li>소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)</Li>
                            <Li>홈페이지 : www.kopico.go.kr</Li>
                            <Li>전화 : 1833-6972</Li>
                            <Li>주소 : (03171) 서울시 종로구 세종대로 209 정부서울청사 12층 개인정보 분쟁조정위원회</Li>
                        </ul>
                    </Li>
                    <Li>대검찰청 사이버수사과
                        <ul>
                            <Li>☎1301 (www.spo.go.kr, cid@spo.go.kr)</Li>
                        </ul>
                    </Li>
                    <Li>경찰청 사이버테러대응센터
                        <ul>
                            <Li>☎182 (cyberbureau.police.go.kr)</Li>
                        </ul>
                    </Li>
                </ul>
                <Li>또한, 개인정보의 열람, 정정·삭제, 처리정지 등에 대한 정보주체자의 요구에 대하여 공공기 관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익을 침해 받은 자는 행정심판법이 정하는 바에 따라 행정심판을
                    청구할 수 있습니다. 중앙행정심판위원회 (국번없이) 110 (www.simpan.go.kr)</Li>
            </ol>
            <H5>제14조 (개인정보 처리방침의 변경에 관한 사항)</H5>
            <ol>
                <Li>본 방침은 2023년 4월 1일부터 시행됩니다.</Li>
            </ol>
            <p>2023. 04. 01 ~ 적용지침</p>
            <div style={{marginBottom: "180px"}}/>

        </ContentContainer>
    );
};

export default PrivacyPolicyKrFragment;