import {ContentContainer} from "../styledcomponents/Containers";
import React from "react";
import styled from "styled-components";

/**
 * @author jaeho.lee104 on 2023. 04. 06..
 */
const EmailPolicyFragment = () => {

    const P = styled.p`
      font-size: 16px;
      line-height: 1.7;
    `

    return (
        <ContentContainer width={"100%"}
                          maxWidth={"800px"}
                          alignItems={"flex-start"}
                          style={{textAlign: "start"}}
                          paddingLeft={"20px"}
                          paddingRight={"20px"}>
            <h3 style={{marginTop: "80px", alignSelf: "center"}}>THIRD WORKS 이메일 무단 수집 거부</h3>
            <P style={{marginTop: "60px"}}>
                정보통신망법 제50조의 2, 제50조의 7 등에 의거하여 주식회사 써드웍스(THIRD WORKS INC)가 운영·관리하는 웹페이지에 게시된 이메일 주소가 전자우편 수집 프로그램이나 그 밖의 기술적 장치 등을 이용하여
                무단으로 수집되는 것을 거부합니다.
                또한 주식회사 써드웍스(THIRD WORKS INC)의 동의 없이 영리 목적의 광고성 정보를 게시하는 행위를 거부합니다.
                이를 위반 시 정보통신망 법에 의해 형사 처벌됨을 유념하시기 바랍니다.
            </P>
            <P>
                [게시일 2023년 4월 1일]
            </P>
        </ContentContainer>
    )
}
export default EmailPolicyFragment;