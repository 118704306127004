import React from "react";
import {ContentContainer} from "../styledcomponents/Containers";
import styled from "styled-components";
import {ColumnsType} from "antd/es/table";
import {Table} from "antd";

/**
 * @author jaeho.lee104 on 2023. 04. 04..
 */
const TermsOfServiceKrFragment = () => {

    const Li = styled.li`
      margin-top: 6px;
      font-size: 16px;
      line-height: 1.7;
    `
    const P = styled.p`
      font-size: 16px;
      line-height: 1.7;
    `
    const H4 = styled.h4`
      font-size: 24px;
      margin-top: 60px;
    `
    const H5 = styled.h5`
      font-size: 20px;
      margin-top: 40px;
    `

    interface DataType {
        key: string;
        service: string;
        content: string;
        freeUser: string;
        paymentUser: string;
    }

    const columns: ColumnsType<DataType> = [
        {
            title: 'No',
            align: "center",
            dataIndex: 'key',
            onCell: (_, index) => {
                if (index === 1 || index === 4) {
                    return {rowSpan: 3};
                }
                if (index === 2 || index === 3 || index === 5 || index === 6) {
                    return {rowSpan: 0};
                }

                return {};
            },
        },
        {
            title: '서비스',
            align: "center",
            dataIndex: 'service',
            onCell: (_, index) => {
                if (index === 0 || index === 7) {
                    return {colSpan: 2};
                }
                if (index === 1 || index === 4) {
                    return {rowSpan: 3}
                }
                if (index === 2 || index === 3 || index === 5 || index === 6) {
                    return {rowSpan: 0}
                }
                return {};
            },
        },
        {
            title: '내용',
            align: "center",
            dataIndex: 'content',
            onCell: (_, index) => {
                if (index === 0 || index === 7) {
                    return {colSpan: 0};
                }
                return {};
            },
        },
        {
            title: '무료회원',
            dataIndex: 'freeUser',
            align: "center"
        },
        {
            title: '유료회원',
            dataIndex: 'paymentUser',
            align: "center"
        }
    ];

    const data: DataType[] = [
        {
            key: '0',
            service: '이용자 정의',
            content: "",
            freeUser: "✓",
            paymentUser: "✓"
        },
        {
            key: '1',
            service: "글로벌소싱인텔리전스",
            content: '수입물품 통계정보 서비스 (수입량, 수입순위 등)',
            freeUser: "✓",
            paymentUser: "✓"
        },
        {
            key: '12',
            service: "",
            content: '수입통관 통계정보 서비스 (통관가격, 통관주기, 통관날짜 등)',
            freeUser: "",
            paymentUser: "✓"
        },
        {
            key: '13',
            service: "",
            content: '수입거래 통계정보 서비스 (거래대상자, 거래품목, 시장점유율 등)',
            freeUser: "",
            paymentUser: "✓"
        },
        {
            key: '2',
            service: "수입관리솔루션",
            content: '수입상품, 거래대상자 탐색 및 정보제공 서비스(상품소싱)',
            freeUser: "✓",
            paymentUser: "✓"
        },
        {
            key: '22',
            service: "",
            content: '상품계약요건 정보제공 서비스(가격, 포장형태, 인증서 등)',
            freeUser: "✓",
            paymentUser: "✓"
        },
        {
            key: '23',
            service: "",
            content: '수입절차요건 정보제공 서비스(수입절차, 서류작성가이드 등)',
            freeUser: "",
            paymentUser: "✓"
        },
        {
            key: '3',
            service: '기타 회사가 자체 개발하거나 다른 회사와의 협력계약을 통해 제공하는 일체의 서비스',
            content: "",
            freeUser: "",
            paymentUser: "✓"
        },
    ];

    return (
        <ContentContainer width={"100%"}
                          maxWidth={"800px"}
                          alignItems={"flex-start"}
                          style={{textAlign: "start"}}
                          paddingLeft={"20px"}
                          paddingRight={"20px"}>
            <h3 style={{marginTop: "80px", alignSelf: "center"}}>THIRD WORKS 이용약관</h3>
            <H4>제 1 장 총칙</H4>
            <H5>제1조 (목적)</H5>
            <P>
                본 약관은 주식회사 써드웍스(이하 “회사”라 한다)가 제공하는 THIRD WORKS 서비스(<a
                href={"http://www.thirdworks.co.kr"}>https://www.thirdworks.co.kr</a>, 이하 “서비스”라 한다) 이용에 관한 회사와 이용자의
                권리·의무 책임사항 및 기타 필요한 사항을 규정하는 것을 목적으로 합니다.
            </P>
            <H5>제2조 (용어의 정의)</H5>
            <P>이 약관에서 사용하는 용어의 정의는 다음 각 호와 같습니다.</P>
            <ol type={"1"}>
                <Li>이용자: 회사가 제공하는 서비스에 대하여 본 약관에 정해진 바에 따라 제공하는 서비스를 이용하는 “회원” 및 “비회원”을 의미합니다.</Li>
                <Li>회원: 이 약관에 동의하고 회원으로 가입하여 회사가 제공하는 서비스를 지속적으로 제공받는자를 의미합니다.
                    <ol type={"a"}>
                        <Li>일반회원(무료): 본 약관에 따라 회원가입을 한 뒤 제한적인 서비스를 이용하는 자를 의미합니다.</Li>
                        <Li>기업관리자(유료): 회사가 요구하는 기업정보를 제공하여 회원가입 후 회사의 담당자로부터 최종승인을 받아 기업이 이용중인 서비스 전체에 대한 관리자 권한을 부여받은 자를 의미합니다.</Li>
                        <Li>기업회원(유료): 회원이 속한 기업관리자로부터 이용권한을 부여받아 서비스를 이용하는 구성원을 의미합니다.</Li>
                    </ol>
                </Li>
                <Li>비회원: 회원으로 가입하지 않은 상태로 회사가 제공하는 제한적인 서비스를 이용하는 자로, 원칙적으로 이 약관의 적용을 받지 않지만 관계법령에 근거하여 이 약관이 금지하는 행위를 할 경우 이 약관에서 정한 바에
                    따라 이용에 제약을 받으 수 있으며 관련법령에 의한 제재를 받을 수 있습니다.
                </Li>
                <Li>아이디(ID): 회사가 제공하는 서비스 이용을 위하여 이용자가 선정하고 회사가 승인하는 비즈니스 이메일 주소로 회원은 1개의 아이디(ID) 사용을 원칙으로 합니다.</Li>
                <Li>비밀번호: 아이디에 대한 본인 여부를 확인하기 위하여 사용하는 문자, 숫자, 특수문자 등의 조합</Li>
                <Li>글로벌소싱인텔리전스: 수입상품의 연도별, 국가별 수입량, 수입순위, 기업별 수입품목, 시장점유율, 거래관계분석 및 이에 부수하는 시장분석서비스 일체를 의미합니다.</Li>
                <Li>수입관리솔루션: 글로벌소싱인텔리전스 데이터를 기반으로 수입상품, 거래선, 계약·수입요건 등 수입정보 제공 및 수입절차에 따른 기업용 수입업무관리 전자시스템을 의미합니다.</Li>
                <Li>계정탈퇴: 회원이 이용계약을 종료하고, 회원자격을 포기하는 행위를 의미합니다.</Li>
            </ol>
            <H5>제3조 (약관의 효력 및 변경)</H5>
            <ol type={"1"}>
                <Li>본 약관은 서비스 사이트에 게시함으로서 효력이 발생합니다.</Li>
                <Li>회사는 “약관의 규제에 관한 법률”, “정보통신망 이용촉진 및 정보보호등에 관한 법률”등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있으며 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 제
                    1항의 방법으로 그 적용일자 7일전부터 적용일자 전일까지 공지합니다.
                </Li>
                <Li>제2항의 적용일자 이후에 이용자가 서비스를 이용하는 경우에는 개정약관에 동의한 것으로 간주하며 이용자는 변경된 약관에 동의하지 않을 경우 이용계약을 해지할 수 있습니다.</Li>
            </ol>
            <H5>제4조 (약관 외 준칙)</H5>
            <ol type={"1"}>
                <Li>회사는 유료서비스 및 개별 서비스에 대하여 별도의 이용약관 및 정책을 둘수 있으며, 해당 내용이 이 약관과 상충할 경우에는 별도로 정한 약관 및 정책이 우선하여 적용됩니다.</Li>
                <Li>이 약관에서 정하지 아니한 내용이나 해석에 대해서는 관계법령 또는 일반적인 상관례가 우선되며, 이용약관을 근거로 면책을 요구할 수 없습니다.</Li>
            </ol>
            <H4>제 2 장 서비스 이용계약</H4>
            <H5>제5조(이용계약의 체결)</H5>
            <ol type={"1"}>
                <Li>이용계약은 회원이 되고자 하는 자(이하“가입신청자)가 약관의 내용에 대하여 동의를 한 다음 회원가입 신청을 하고, 회사가 이러한 신청에 대하여 승낙함으로써 체결됩니다.</Li>
                <Li>가입신청자는 가입신청 시 반드시 본인의 진실한 정보를 제공하여야 하며, 타인의 개인정보를 이용하여 회사와 이용계약을 체결한 경우에는 어떠한 이유로도 보호 받지 못하며, 경우에 따라서는 관계법령에 의해 처벌
                    받을 수 있습니다.</Li>
                <Li>또한 회사는 회원가입 시 가입신청자가 제공하는 정보에 대해 본인 여부를 확인할 수 있으며 가입신청자는 이에 협력하여야 합니다.</Li>
            </ol>
            <H5>제6조(이용신청의 승낙 및 제한)</H5>
            <ol type={"1"}>
                <Li>회사는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.
                    <ol type={"a"}>
                        <Li>가입신청자가 본 약관에 의하여 이전에 이용자 자격을 상실한 적이 있는 경우, 다만, 이용자 자격 상실 후 1년이 경과한 자로서 회사의 이용자 재가입 승낙을 얻은 경우에는 예외로 합니다.</Li>
                        <Li>제3자의 명의 또는 법인 명의를 사용하는 등 회원가입 시 필수정보 및 첨부서류를 허위로 제공하거나 고의로 누락, 오기하여 신청한 경우</Li>
                        <Li>사회의 안정질서 또는 미풍양속을 저해할 목적으로 신청하였을 경우</Li>
                        <Li>서비스 관련 제반 설비의 부족 혹은 기술상 장애 사유가 있어 이용계약을 체결하는 것이 현저히 곤란한 경우</Li>
                        <Li>신청자의 신청행위가 회사의 업무수행에 중대한 지장을 초래하기 위한 목적인 경우</Li>
                        <Li>기타 이용계약의 체결이 관계법령이나 이 약관에 위배된다고 합리적으로 판단되는 경우</Li>
                        <Li>회사는 이용계약의 체결 이후라도 제6조 각호에 해당하는 사실을 발견한 경우에는 서비스 이용을 중지 또는 제한할 수 있으며, 경우에 따라서는 이용계약을 해지하고 회원가입을 취소할 수
                            있습니다.</Li>
                    </ol>
                </Li>
            </ol>
            <H5>제7조 (회원정보의 변경)</H5>
            <ol type={"1"}>
                <Li>회원은 계정정보 관리화면을 통하여 언제든지 자신의 개인정보를 열람하고 수정할 수 있습니다. 다만 회사의 서비스 관리를 위해 필요한 회사명, 아이디 등은 별도의 전자우편 또는 기타 방법으로 회사에 요청하여
                    수정할 수 있습니다.</Li>
                <Li>회원은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타 방법으로 회사에 대하여 그 변경사항을 알려야 합니다.</Li>
                <Li>제2항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.</Li>
            </ol>
            <H5>제8조 (개인정보의 보호)</H5>
            <ol type={"1"}>
                <Li>회사는 관계 법령이 정하는 바에 따라 회사가 서비스와 관련하여 수집한 개인정보를 보호하기 위해 노력하며, 개인정보의 보호 및 사용에 대해서는 관계 법령 및 회사의 개인정보처리방침에 따릅니다. 다만, 회사가
                    제공하는 서비스 이외의 링크된 서비스에 대해서는 회사의 개인정보처리방침이 적용되지 않습니다.</Li>
                <Li>회사는 다음 각 호의 경우에 법에서 허용하는 범위 내에서 이용자의 정보를 제3자에게 제공할 수 있습니다.
                    <ol type={"a"}>
                        <Li>“이용자”가 “서비스”를 이용함에 있어 관련 법률을 위반하여 수사기관이나 기타 정부기관으로부터 정보 제공을 요청받은 경우</Li>
                        <Li>“이용자”의 법률위반, 본 서비스 약관위반을 포함하여 부정행위 확인 등의 정보보호 업무를 위해 필요한 경우</Li>
                        <Li>기타 법률에 의해 요구되는 경우</Li>
                    </ol>
                </Li>
            </ol>
            <H5>제9조(이용계약 해제·해지)</H5>
            <ol type={"1"}>
                <Li>이용자는 언제든지 사이트내 회원탈퇴, 전자우편, 전화, 팩스 및 기타 방법으로 해지 의사를 통지하여 이용계약을 해지할 수 있습니다.</Li>
                <Li>회사는 다음 각 호의 사유가 있을 경우 이용계약을 해지할 수 있으며 일정 기간 내에 재가입을 제한 할 수 있습니다. 이 경우 회사는 사용자에게 사전에 해지사유에 대한 의견 진술의 기회 부여할 수 있습니다.
                    <ol type={"a"}>
                        <Li>유료서비스 이용요금을 정한 기일 내에 납부하지 아니한 경우</Li>
                        <Li>“이용자”가 이용약관에서 정하고 있는 게시물을 등록하거나, 기타 공공질서 및 미풍양속에 위배되는 물품거래 행위를 시도하거나 시도한 경우</Li>
                        <Li>다른 사람 명의를 도용하여 신청하거나 다른 회사 사업자등록번호를 도용하여 신청한 경우</Li>
                        <Li>관계법령 및 약관을 위반하여 1회 이상의 시정요구를 받고 이에 불응하여 회사의 업무기술상 막대한 지장을 초래한 경우</Li>
                        <Li>기타 회사가 이용자로서 부적당하다고 판단한 경우</Li>
                    </ol>
                </Li>
            </ol>
            <H4>제 3 장 당사자의 의무</H4>
            <H5>제10조(“회사”의 의무)</H5>
            <ol type={"1"}>
                <Li>회사는 관련법령과 이 약관이 정하는 의무를 신의에 따라 성실하게 준수합니다.</Li>
                <Li>회사는 이용자가 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며, 개인정보취급방침을 공시하고 준수합니다.</Li>
                <Li>회사는 서비스 이용과 관련하여 이용자로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다.</Li>
                <Li>이용자가 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여 회원에게 처리과정 및 결과를 전달합니다.</Li>
            </ol>
            <H5>제11조(“이용자”의 의무)</H5>
            <ol type={"1"}>
                <Li>이용자는 회사의 사전 승낙 없이 서비스를 이용하여 어떠한 영리행위도 할 수 없습니다.</Li>
                <Li>이용자는 서비스를 이용하여 얻은 정보를 회사의 사전승낙 없이 복사, 복제, 변경, 번역, 출판, 방송 기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없습니다.</Li>
                <Li>이용자는 서비스 이용과 관련하여 다음 각 호의 행위를 하여서는 안되며, 만약 각호에 해당하는 경우 회사는 해당 이용자에게 서비스 이용에 대한 제한 및 회원자격에 대한 제한 조치를 취할 수 있습니다.
                    <ol type={"a"}>
                        <Li>회원가입 신청 또는 변경 시 허위내용을 등록하는 행위</Li>
                        <Li>회사 및 기타 제3자의 명예를 훼손하거나 모욕하는 등 인격권을 침해하는 행위</Li>
                        <Li>회사 및 기타 제3자의 지적재산권 등 권리를 침해하거나 업무를 방해하는 경우</Li>
                        <Li>타인의 회원ID, 비밀번호 등을 도용하거나, 타인의 명의 및 신용정보를 도용하는 행위</Li>
                        <Li>회사가 제공하는 서비스 이 외의 컴퓨터 프로그램, 솔루션 등을 통해 서비스를 복제, 가공 기타 변형하는 행위</Li>
                        <Li>회사에 직·간접적인 손해를 입힐 목적으로 비정상적인 접속 또는 프로그램을 통해 서버에 부화를 일으키거나 회사의 정상적인 서비스를 방해하는 행위</Li>
                        <Li>다른 이용자의 개인정보를 그의 동의 없이 수집 또는 저장하는 행위</Li>
                        <Li>관계법령 또는 법률관계에 의하여 그 전송 또는 게시가 금지되는 정보 및 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 전송하거나 또는 게시 행위</Li>
                        <Li>회원 아이디(ID)를 본인 외 제3자로 하여금 이용하도록 하는 행위</Li>
                        <Li>회사 사이트에 게시된 정보를 변경하거나 유료서비스 제도를 악용하는 행위</Li>
                        <Li>기타 불법적이거나 부당한 행위</Li>
                    </ol>
                </Li>
            </ol>
            <H4>제 4 장 서비스의 이용</H4>
            <H5>제12조(서비스 내용 등)</H5>
            <ol type={"1"}>
                <Li>회사는 이용자에게 아래와 같은 서비스를 제공합니다.
                    <ol type={"a"}>
                        <Li>글로벌소싱인텔리전스
                            <ul>
                                <Li>수입물품 통계정보 서비스(수입량, 수입순위 등)</Li>
                                <Li>수입통관 통계정보 서비스(통관가격, 통관주기, 통관날짜 등)</Li>
                                <Li>수입거래 통계정보 서비스(거래대상자, 거래품목, 시장점유율 등)</Li>
                            </ul>
                        </Li>
                        <Li>수입관리솔루션
                            <ul>
                                <Li>수입상품, 거래대상자 탐색 및 정보제공 서비스(상품소싱)</Li>
                                <Li>상품계약요건 정보제공 서비스(가격, 포장형태, 인증서 등)</Li>
                                <Li>수입절차요건 정보제공 서비스(수입절차, 서류작성가이드 등)</Li>
                            </ul>
                        </Li>
                        <Li>기타 회사가 자체 개발하거나 다른 회사와의 협력계약을 통해 제공하는 일체의 서비스</Li>
                    </ol>
                </Li>
                <Li>서비스의 이용시간은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간을 원칙으로 합니다.</Li>
                <Li>다만, 회사가 사전에 공지하여 정기점검 등의 필요로 정한 날이나 시간의 경우 중단할 수 있으며, 이 외의 정보통신설비 등의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 서비스의
                    제공을 일시적으로 중단할 수 있습니다.</Li>
            </ol>
            <H5>제13조(서비스의 변경)</H5>
            <ol type={"1"}>
                <Li>회사는 필요 시 서비스의 내용을 추가, 변경, 삭제할 수 있으며, 이에 따른 이용에 필요한 사항은 회사가 정하여 이용안내에 게시 또는 별도로 공시하는 내용에 따릅니다.</Li>
            </ol>
            <H5>제14조(게시물의 관리)</H5>
            <ol>
                <Li>이용자가 작성 또는 게시한 게시물 등 일체의 자료에 대한 권리와 책임은 회원에게 있습니다. 따라서 이용자가 게시한 게시물로 인하여 발생하는 모든 민·형사상 책임은 이용자 자신에게 있습니다.</Li>
                <Li>이용자는 다음 각호에 해당하는 게시물을 게시하거나 전송할 수 없으며, 회사는 서비스 내에 존재하는 게시물이 다음 각호에 해당한다고 판단되는 경우 해당 게시물을 삭제, 임시조치, 이동 또는 등록거부 할 수
                    있습니다.
                    <ol type={"a"}>
                        <Li>다른 이용자, 또는 제3자를 비방하거나 명예를 손상시키는 내용인 경우</Li>
                        <Li>외설물, 음란물 등 공공질서 및 미풍양속에 위반되는 내용인 경우</Li>
                        <Li>범죄적 행위에 결부된다고 인정되는 내용인 경우</Li>
                        <Li>회사 또는 제3자의 저작권 등 기타 타인의 권리를 침해하는 내용인 경우</Li>
                        <Li>법령을 위반하거나 타인의 권리를 침해할 목적으로 정치적, 종교적, 사회적 분쟁을 야기하는 경우</Li>
                        <Li>영리 혹은 비영리를 목적으로 승인되지 않은 광고, 판촉물을 게재하는 경우</Li>
                        <Li>타인의 개인정보를 도용하여 작성한 내용이거나, 타인이 입력한 정보를 무단으로 위변조한 내용인 경우</Li>
                        <Li>탈퇴한 회원이 게시한 게시물인 경우</Li>
                        <Li>동일한 내용을 중복, 반복하여 다수 게시하는 등 서비스의 목적에 어긋나는 경우</Li>
                        <Li>기타 관계법령 및 약관 등에 위반되는 내용의 게시물인 경우</Li>
                    </ol>
                </Li>
            </ol>
            <H5>제15조(정보 제공 및 광고의 게재)</H5>
            <ol>
                <Li>회사는 서비스의 운영과 관련하여 서비스 내에 광고를 게재할 수 있습니다. 또한 수신에 동의한 회원에 한하여 전자우편, 문자서비스(LMS/SMS) 등의 방법으로 광고성 정보를 전송할 수 있습니다. 이 경우
                    회원은 언제든지 수신을 거절할 수 있으며, 회사는 회원의 수신 거절 시 광고성 정보를 발송하지 아니합니다.</Li>
                <Li>회사가 제공하는 서비스 중의 배너 또는 링크 등을 통해 타인이 제공하는 광고나 서비스에 연결될 수 있습니다.</Li>
                <Li>제2항에 따라 타인이 제공하는 광고나 서비스에 연결될 경우 해당 영역에서 제공하는 서비스는 회사의 서비스 영역이 아니므로 회사가 신뢰성, 안정성 등을 보장하지 않으며, 그로 인한 회원/구성원의 손해에 대하여도
                    회사는 책임을 지지 않습니다.</Li>
            </ol>
            <H5>제16조(저작권등의 귀속)</H5>
            <ol>
                <Li>회사가 제공하는 서비스 및 그에 필요한 소프트웨어, 이미지, 마크, 로고, 디자인, 서비스 명칭, 정보 및 상표 등과 관련된 지적재산권 및 기타 권리는 회사에게 있습니다.</Li>
                <Li>이용자는 회사가 명시적으로 승인한 경우를 제외하고는 제1항에서 규정된 권리의 전부 또는 일부를 수정, 대여, 대출, 판매, 배포, 제작, 양도, 재라이센스, 담보권 설정 행위, 상업적 이용 행위를 할 수
                    없으며, 제3자로 하여금 이와 같은 행위를 하도록 허락할 수 없습니다.</Li>
                <Li>이용자가 제2항을 위반한 경우에 이용자는 회사에 대하여 그에 따르는 민·형사상의 책임을 져야 합니다.</Li>
            </ol>
            <H4>제 5 장 유료서비스의 이용</H4>
            <H5>제17조(유료회원 가입)</H5>
            <ol>
                <Li>“유료회원”이 되고자 하는 회원은 회사가 정한 절차에 따라 필요한 정보를 기입하고 이 약관에 동의함으로써 가입을 신청할 수 있으며, 결격사유가 없는 경우 회사는 가입신청을 승인합니다.</Li>
                <Li>회사는 제1항의 신청과정에서 회원이 제공한 정보에 대하여 유효성 인증을 할 수 있습니다.</Li>
                <Li>“유료회원”이 유료회원의 혜택을 운영취지에 부합하지 않는 방식으로 부정하게 사용할 경우, 회사는 해당 회원에 대한 혜택 제공을 제한하거나 탈퇴처리를 할 수 있습니다.</Li>
            </ol>
            <H5>제18조(유료회원의 이용기간)</H5>
            <ol>
                <Li>“유료회원”은 가입 즉시부터 유료회원 서비스를 이용할 수 있습니다.</Li>
                <Li>월 단위 “유료회원”의 이용기간 종료일은 가입일(D)로부터 30일이 경과하는 날(D+30) 이며 연 단위 “유료회원”의 이용기간 종료일은 가입일(D)로부터 365일이 경과하는 날(D+365)입니다.</Li>
                <Li>“유료회원”이 이용료 자동결제에 동의하여 결제가 완료된 경우, 월 단위 “유료회원” 이용기간은 자동결제일을 포함하여 30일, 연 단위 “유료회원”의 이용기간은 365일 갱신됩니다.</Li>
                <Li>“유료회원”이 이용료 자동결제에 동의하였더라도 결제가 완료되지 아니하면 이용기간은 갱신되지 아니합니다.</Li>
            </ol>
            <H5>제19조(유료서비스의 내용)</H5>
            <ol>
                <Li>회사는 이용료를 납부한 “유료회원”에게 “유료회원” 혜택을 제공하며 회사가 운영하는 사이트(<a
                    href={"https://www.thirdworks.co.kr"}>https://www.thirdworks.co.kr</a>)를 통해 “유료회원” 혜택의 내용을 고지합니다. 단, 혜택의 내용 및 제공 방법은
                    회사의 정책에 따라 변경될 수 있습니다.</Li>
            </ol>
            <Table columns={columns}
                   dataSource={data}
                   pagination={false}
                   bordered/>
            <H5>제20조 (서비스 이용요금)</H5>
            <ol>
                <Li>서비스 이용요금은 회원에게 제공되는 서비스 중 유료서비스를 이용하기 위해 지불하는 요금으로 회사의 요금정책에 따라 회원의 종류, 사용기간 및 제공정보의 유형과 정보량 등에 따라 차등하게 적용할 수
                    있습니다.</Li>
                <Li>이용요금은 회사의 요금정책이나 정부기관의 고시 개정 등에 따라 변경될 수 있으며, 이 경우 각 서비스 화면 등에서 변경 전 15일간 공지하고 이를 변경할 수 있습니다.</Li>
                <Li>회사는 프로모션 등 별도로 정한 바에 따라 회원에게 이용요금을 감면할 수 있고, 기타 혜택을 부여할 수 있습니다.</Li>
            </ol>
            {/*<H5>제21조(이용료 정기결제)</H5>*/}
            {/*<ol>*/}
            {/*    <Li>“유료회원”은 이용기간 종료일(이하 “정기결제예정일”이라 함) 밤 12시 이후 자신이 제공한 결제정보에 의해 이용료가 자동으로 정기결제되는 것에 동의합니다.</Li>*/}
            {/*    <Li>“유료회원”은 정기결제 예정일 1일 전까지 사이트의 마이페이지에서 정기결제를 해지를 신청할 수 있습니다.</Li>*/}
            {/*    <Li>“유료회원”은 “유료회원” 혜택의 수령 및 이용료 정기결제를 위해 회사에 제공하는 개인정보를 최신으로 유지하여야 합니다.</Li>*/}
            {/*    <Li>회사는 “유료회원”이 제3항의 규정을 위반하여 발생하는 불이익에 대하여 책임지지 않습니다.</Li>*/}
            {/*    <Li>회사는 유료회원에게 이용료 결제 사실을 통지하여야 하며, 납입영수내역을 서면 또는 전산망을 통하여 교부하여야 합니다.(사이트의 마이페이지에 통지)</Li>*/}
            {/*</ol>*/}
            <H5>제22조(환불정책)</H5>
            <ol>
                <Li>회사는 “유료회원”이 “유료서비스”를 가입한 후 “유료회원”의 혜택을 단한번도 사용하지 않은(유료회원 서비스 조회 기록 전무) 경우에 한하여 서비스 이용료를 환불합니다.</Li>
                {/*<Li>“유료회원”이 “유료회원” 서비스 이용기간 중 정기결제 해지 시 이용기간이 만료될 때까지는 “유료회원” 서비스를 제공합니다. (정기결제 해지 신청 시점부터 이용기간 만료까지의 기간에 대해서는 환불*/}
                {/*    불가)</Li>*/}
            </ol>
            <H5>제23조(유료회원 혜택의 제공 중단)</H5>
            <ol>
                <Li>“유료회원”이 이용료를 납부하지 아니한 경우 회사는 “유료회원”에 대한 혜택 제공을 즉시 중단합니다. 단, “유료회원”이 이용료를 납부하면 그날로부터 “유료회원” 혜택을 다시 제공합니다.</Li>
                <Li>회사는 경영환경의 변화, 영업정책의 변경을 사유로 “유료회원”의 혜택 제공을 중단할 수 있습니다. 이 경우 중단일 30일 전까지 회사가 운영하는 매체를 통하여 “유료회원”에게 이를 공지하여야 합니다.</Li>
            </ol>
            <H4>제 6 장 손해배상 및 면책조항 등</H4>
            <H5>제24조(양도 등의 금지)</H5>
            <ol>
                <Li>이용자는 서비스를 이용할 수 있는 권리를 타인에게 양도 또는 증여하거나 질권의 목적으로 사용할 수 없습니다. 다만, 아래 각 호에서 정하는 바에 따라 회사의 사전승인을 받은 경우에는 예외로 합니다.
                    <ol type={"a"}>
                        <Li>개인기업의 대표자가 사망한 경우 : 대표자의 지위를 승계한 상속인</Li>
                        <Li>기업형태를 변경한 경우 : 변경 후 기업</Li>
                        <Li>법인 상호간 합병을 한 경우 : 존속기업</Li>
                        <Li>둘 이상의 법인이 하나의 법인으로 신설 합병한 경우 : 신설법인</Li>
                        <Li>하나의 법인이 둘 이상의 법인으로 분할된 경우로서 분할된 법인이 사용권을 인수할 경우 : 분할된 법인</Li>
                    </ol>
                </Li>
                <Li>제1항에 의한 예외사항에 해당하는 경우 1개월 이내에 지위 승계 변경사실을 증명하는 서류를 첨부하여 회사에 승계신청을 하여야 하며, 회사는 제반사항을 검토하여 승계 여부를 결정 통지합니다.</Li>
            </ol>
            <H5>제25조(손해배상)</H5>
            <ol>
                <Li>회사는 서비스와 관련하여 회사의 고의 또는 중과실로 회원에게 손해가 발생한 경우 이를 배상할 책임을 부담합니다.</Li>
                <Li>다음 각호의 해당하는 경우에는 회사는 손해배상책임의 책임을 부담하지 않습니다.
                    <ol type={"a"}>
                        <Li>서비스 향상 등을 위해 사전에 공지하거나 정기적으로 실시하는 정기점검으로 인한 서비스 불안정 및 서비스 중지로 인한 경우</Li>
                        <Li>발생한 손해가 이용자의 귀책사유로 인한 것일 경우</Li>
                        <Li>화재, 홍수 등의 천재지변 또는 파업, 전력수급의 차질 등 “진흥원”의 통제범위를 벗어난 사유로 인한 손해나 서비스 지연인 경우</Li>
                        <Li>제1항의 손해배상의 청구는 청구사유, 청구금액 및 산출근거를 기재하여 서면으로 하여야 합니다.</Li>
                        <Li>이용자의 고의 또는 과실로 인한 서비스 이용장애 및 이용제한에 대해서는 배상할 책임이 없습니다.</Li>
                    </ol>
                </Li>
            </ol>
            <H5>제26조(면책조항)</H5>
            <ol>
                <Li>다음 각 호의 사유로 발생된 문제에 대하여 회사는 책임을 지지 않습니다.
                    <ol type={"a"}>
                        <Li>이용자가 서비스를 이용하여 얻은 정보 및 자료 등에 대한 책임</Li>
                        <Li>이용자가 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 기타 어떠한 내용에 관하여 책임</Li>
                        <Li>회사가 제공하는 서비스를 통하여 이루어지는 ”이용자“ 상호간 거래요건, 거래이행사항, 물품배송, 청약 철회 또는 반품, 물품하자로 인한 분쟁해결 등 필요한 사후처리의 경우</Li>
                        <Li>"이용자"의 보안관리 소홀로 인하여 침해사고가 발생한 경우</Li>
                        <Li>이용자가 회사가 제공하는 서비스로부터 기대되는 이익을 얻지 못하였거나 서비스 자료에 대한 취사 선택 또는 이용으로 발생하는 손해에 대한 책임</Li>
                        <Li>이용자가 회사가 제공하는 서비스를 이용하면서 다른 회원으로 인해 입게 되는 정신적· 금전적 피해</Li>
                        <Li>이용자가 자발적, 공개적으로 게시한 내용에 포함된 개인정보는 타인이 수집하여 사용 할 가능성이 있으며, 이러한 위험에 대한 책임</Li>
                        <Li>이용자가 회사의 사이트를 통해 방문하는 다른 사이트로부터 발생하는 모든 책임</Li>
                        <Li>회사가 제공하는 서비스의 이용과 관련하여 회사의 고의 또는 중대한 과실이 없이 회원에게 발생한 손해에 대한 책임</Li>
                    </ol>
                </Li>
            </ol>
            <H5>제27조 (대리 및 보증의 부인)</H5>
            <ol>
                <Li>회사는 ”이용자“ 상호간의 거래를 위한 온라인 거래장소를 제공할 뿐이므로 물품을 판매하거나 구매하고자 하는 ”이용자“를 대리하지 않습니다. 또한 회사가 제공하는 서비스의 어떠한 행위도 특정회원 및 기업,
                    기관을 대리하는 행위로 간주되지 않습니다.</Li>
                <Li>회사가 제공하는 서비스를 통하여 이루어지는 이용자간의 판매 및 구매와 관련하여 판매의사 또는 구매의사의 존부 및 진정성, 등록물품의 품질, 완전성, 안정성, 적합성 및 타인의 권리에 대한 비침해성, 게재된
                    자료의 진실성 등 일체에 대하여 보증하지 아니하며, 이와 관련한 일체의 위험과 책임은 해당 이용자가 부담해야 합니다.</Li>
                <Li>회사는 ”이용자“가 게재하는 물품설명 등의 제반 정보를 통제하거나 제한하지 않습니다. 다만 ”이용자“가 게재한 정보의 내용이 타인의 명예, 권리를 침해하거나 규정을 위반한다고 판단하는 경우에는 이를 승인
                    거부, 삭제 및 기타 필요한 조치를 취할 수 있습니다.</Li>
            </ol>
            <H5>제28조 (재판권 및 준거법)</H5>
            <ol>
                <Li>회사와 “이용자”간 제기된 소송은 대한민국법을 준거법으로 합니다.</Li>
                <Li>회사와 “이용자”간 발생한 분쟁에 관한 소송은 상호 신의성실에 바탕한 협의로 해결하도록 하되 상호 협의가 어려울 경우 민사소송법상의 관할을 가지는 회사의 소재지 관할법원에 제소합니다.</Li>
            </ol>
            <P style={{marginTop: "40px"}}>
                부칙<br/>
                제1조 (시행일) 본 약관은 2023년 4월 1일부터 적용됩니다.
            </P>
            <div style={{marginBottom: "180px"}}/>
        </ContentContainer>
    );
};

export default TermsOfServiceKrFragment;