import {ContentContainer} from "../styledcomponents/Containers";
import React from "react";
import styled from "styled-components";

/**
 * @author jaeho.lee104 on 2023. 04. 06..
 */
const CopyrightPolicyFragment = () => {

    const P = styled.p`
      font-size: 16px;
      line-height: 1.7;
    `

    return (
        <ContentContainer width={"100%"}
                          maxWidth={"800px"}
                          alignItems={"flex-start"}
                          style={{textAlign: "start"}}
                          paddingLeft={"20px"}
                          paddingRight={"20px"}>
            <h3 style={{marginTop: "80px", alignSelf: "center"}}>THIRD WORKS 저작권 정책</h3>
            <P style={{marginTop: "60px"}}>
                주식회사 써드웍스(THIRD WORKS INC) 홈페이지에서 제공하는 모든 콘텐츠 즉, 웹문서, 첨부파일, DB정보 등은 저작권법에 의하여 보호받는 저작물로, 별도의 저작권 표시 또는 다른 출처를 명시한 경우를
                제외하고는 원칙적으로 회사에 저작권이 있습니다. 이를 위반하여 회사에서 제공하는 콘텐츠 및 테이터 등 일체에 대하여 무단 복제, 배포하는 경우에는 「저작권법 제136조 1」에 의한 저작재산권 침해에 해당하여 5년
                이하의 징역 또는 5천만원 이하의 벌금에 처해질 수 있습니다.
            </P>
        </ContentContainer>
    )
}
export default CopyrightPolicyFragment;