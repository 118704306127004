import React from "react";
import {Navigate} from "react-router-dom";

/**
 * @author jaeho.lee104 on 2023. 04. 07..
 */
const NotFoundFragment = () => {
    return (
        <Navigate to={"/"}/>
    )
}
export default NotFoundFragment;