/**
 * @author jaeho.lee104 on 2023. 04. 20..
 */
export type CountryModel = {
    countryCode: string,
    countryName: string,
    countryNumber: string
}

export const DEFAULT_COUNTRIES: Array<CountryModel> = [
    {
        countryCode: "KR",
        countryName: "대한민국",
        countryNumber: "82"
    },
    {
        countryCode: "US",
        countryName: "미국",
        countryNumber: "1"
    },
    {
        countryCode: "CN",
        countryName: "중국",
        countryNumber: "86"
    },
    {
        countryCode: "JP",
        countryName: "일본",
        countryNumber: "81"
    },
    {
        countryCode: "TH",
        countryName: "태국",
        countryNumber: "66"
    },
]