import styled from "styled-components";
import CommonComponent from "./CommonComponent";
import {Sizes} from "./Styles";

export const FlexContainer = styled(CommonComponent)`
  display: ${({display}) => display || "flex"};
  width: ${({width}) => width || "fit-content"};
  margin: 0 auto;
  position: relative;
`;

export const RootContainer = styled(CommonComponent)`
  margin: 0 auto;
  display: flex;
  flex-direction: ${({flexDirection}) => flexDirection || "column"};
  width: ${({width}) => width || "100%"};
  height: ${({height}) => height || "100%"};
  position: relative;
`;

export const ReactiveContentContainer = styled(CommonComponent)`
  margin: 0 auto;
  display: flex;
  flex-direction: ${({flexDirection}) => flexDirection || "column"};
  width: 100%;
  max-width: ${({maxWidth}) => maxWidth || Sizes.CONTENT_WIDTH};
  height: ${({height}) => height || "100%"};
  position: relative;
`;

export const ContentContainer = styled(CommonComponent)`
  margin: 0 auto;
  display: flex;
  flex-direction: ${({flexDirection}) => flexDirection || "column"};
  height: ${({height}) => height || "100%"};
  width: ${({width}) => width || Sizes.CONTENT_WIDTH};
  max-width: ${({maxWidth}) => maxWidth || Sizes.CONTENT_WIDTH};
  position: relative;
`;