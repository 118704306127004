import styled from "styled-components";
import {CommonProps} from "./CommonComponent";

export interface LiProps extends CommonProps {

}

const Li = styled.li<LiProps>`
  width: ${({width}) => width || "fit-content"};
  text-align: ${({textAlign}) => textAlign || "left"};
  line-height: ${({lineHeight}) => lineHeight || "normal"};
  height: ${({height}) => height || "fit-content"};
  font-size: ${({fontSize}) => fontSize || "16px"};
  font-weight: ${({fontWeight}) => fontWeight || "normal"};

  box-shadow: ${({boxShadow}) => boxShadow || undefined};
  opacity: ${({opacity}) => opacity || undefined};
  flex-grow: ${({flexGrow}) => flexGrow || undefined};
  position: ${({position}) => position || undefined};
  border-radius: ${({borderRadius}) => borderRadius || undefined};
  float: ${({float}) => float || undefined};
  margin: ${({margin}) => margin || undefined};
  margin-left: ${({marginLeft, margin}) => (!margin && marginLeft) || undefined};
  margin-right: ${({marginRight, margin}) => (!margin && marginRight) || undefined};
  margin-bottom: ${({marginBottom, margin}) => (!margin && marginBottom) || undefined};
  margin-top: ${({marginTop, margin}) => (!margin && marginTop) || undefined};
  padding-left: ${({paddingLeft}) => paddingLeft || undefined};
  padding-right: ${({paddingRight}) => paddingRight || undefined};
  padding-bottom: ${({paddingBottom}) => paddingBottom || undefined};
  padding-top: ${({paddingTop}) => paddingTop || undefined};
  min-width: ${({minWidth}) => minWidth || undefined};
  min-height: ${({minHeight}) => minHeight || undefined};
  max-width: ${({maxWidth}) => maxWidth || undefined};
  max-height: ${({maxHeight}) => maxHeight || undefined};
  border: ${({border}) => border || undefined};
  border-right: ${({borderRight}) => borderRight || undefined};
  border-left: ${({borderLeft}) => borderLeft || undefined};
  border-top: ${({borderTop}) => borderTop || undefined};
  border-bottom: ${({borderBottom}) => borderBottom || undefined};
  color: ${({color}) => color || undefined};
  display: ${({display}) => display || undefined};
  align-content: ${({alignContent}) => alignContent || undefined};
  align-items: ${({alignItems}) => alignItems || undefined};
  flex-direction: ${({flexDirection}) => flexDirection || undefined};
  flex-wrap: ${({flexWrap}) => flexWrap || undefined};
  justify-content: ${({justifyContent}) => justifyContent || undefined};
  align-self: ${({alignSelf}) => alignSelf || undefined};
  background-color: ${({backgroundColor}) => backgroundColor || undefined};
  background: ${({background}) => background || undefined};
  cursor: ${({cursor}) => cursor || undefined};
  right: ${({right}) => right || undefined};
  left: ${({left}) => left || undefined};
  top: ${({top}) => top || undefined};
  bottom: ${({bottom}) => bottom || undefined};
  z-index: ${({zIndex}) => zIndex || undefined};
  aspect-ratio: ${({aspectRatio}) => aspectRatio || undefined};;

  &:hover {
    border: ${({border, borderOnHover}) => borderOnHover || border || undefined};
  }
`;

export default Li;