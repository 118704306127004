export class ChangePasswordWithKeyRequestBody {
    key: string;
    newPass: string;
    newPassChk: string;

    constructor(key: string, newPass: string, newPassChk: string) {
        this.key = key;
        this.newPass = newPass;
        this.newPassChk = newPassChk;
    }
}