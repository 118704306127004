/**
 * @author jaeho.lee104 on 2023. 08. 08..
 */
import styled from "styled-components";
import {ColumnsType} from "antd/es/table";
import {ContentContainer} from "../styledcomponents/Containers";
import {Table} from "antd";
import React from "react";

const PrivacyPolicyEnFragment = () => {

    const Li = styled.li`
      margin-top: 6px;
      font-size: 16px;
      line-height: 1.7;
    `
    const P = styled.p`
      font-size: 16px;
      line-height: 1.7;
    `

    const H5 = styled.h5`
      font-size: 20px;
      margin-top: 40px;
    `

    interface DelegateTableDataType {
        delegator: string;
        job: string;
        expiredDate: string;
    }

    const delegateColumns: ColumnsType<DelegateTableDataType> = [
        {
            title: 'Consignee(Trustee)',
            align: "center",
            dataIndex: 'delegator',
        },
        {
            title: 'Consignment work',
            align: "center",
            dataIndex: 'job',
        },
        {
            title: 'Retention and processing period',
            align: "center",
            dataIndex: 'expiredDate',
        }
    ];
    const delegateData: DelegateTableDataType[] = [
        {
            delegator: 'Toss Payments',
            job: 'Billing and payment processed by Payment gateway',
            expiredDate: "At the end of the consignment contract",
        },
        {
            delegator: 'Payple',
            job: 'Billing and payment processed by Payment gateway',
            expiredDate: "At the end of the consignment contract",
        }
    ]

    interface CollectTableDataType {
        collectItem: string;
        collectMethod: string;
        collectPurpose: string;
        collectExpiredDate: string;
    }

    const collectTableColumns: ColumnsType<CollectTableDataType> = [
        {
            title: 'Items of behavioral information',
            align: "center",
            dataIndex: 'collectItem',
        },
        {
            title: 'Method of collecting behavioral information',
            align: "center",
            dataIndex: 'collectMethod',
        },
        {
            title: 'Purpose of collecting behavioral information',
            align: "center",
            dataIndex: 'collectPurpose',
        },
        {
            title: 'Retention/use period and information disposal method',
            align: "center",
            dataIndex: 'collectExpiredDate',
        }
    ];
    const collectTableData: CollectTableDataType[] = [
        {
            collectItem: 'User\'s website/app service visit history, search history, transaction history',
            collectMethod: 'Automatic collection when users visit/run websites and apps',
            collectPurpose: "To provide personalized services and transaction recommendation (including advertisements) based on users' interests and tendencies.",
            collectExpiredDate: "Destroying the information after 1 year from the date of collection"
        },
    ]

    interface ManagerTableDataType {
        label: string;
        content: string
    }

    const managerTableColumns: ColumnsType<ManagerTableDataType> = [
        {
            title: '',
            align: "center",
            dataIndex: 'label',
        }, {
            title: 'Personal Information Protection Officer',
            align: "center",
            dataIndex: 'content',
        },
    ];
    const managerTableData: ManagerTableDataType[] = [
        {
            label: 'Department',
            content: 'Data Management Team',
        },
        {
            label: 'Name',
            content: 'Kim Min-ji',
        },
        {
            label: 'Contact',
            content: '010-2476-9700',
        },
        {
            label: 'Email',
            content: 'business@thirdworks.co.kr',
        },
    ]

    return (
        <ContentContainer
            width={"100%"}
            maxWidth={"800px"}
            alignItems={"flex-start"}
            style={{textAlign: "start"}}
            paddingLeft={"20px"}
            paddingRight={"20px"}>
            <h3 style={{marginTop: "80px", alignSelf: "center"}}>THIRD WORKS Personal Information Policy</h3>
            <P style={{marginTop: "60px"}}>
                Third Works Co., Ltd. (hereinafter referred to as the “Company”) legally processes and safely manages personal information
                in compliance with the 「Personal Information Protection Act」 and related laws to protect the freedom and rights of
                customers. Accordingly, in accordance with Article 30 of the 「Personal Information Protection Act」, we have established and
                disclosed the following personal information processing policy in order to guide information subjects on the procedures and
                standards for personal information processing, and to promptly and smoothly handle complaints related to this.
            </P>
            <H5>Article 1 (Purpose of processing personal information)</H5>
            <ol>
                <Li>The company processes personal information to a minimum as necessary for the purpose of providing customer service,
                    handling civil complaints, and carrying out relevant duties. If the purpose of use is changed, we plan to take necessary
                    measures, such as obtaining separate consent in accordance with Article 18 of the 「Personal Information Protection Act」.
                    <ol type={"a"}>
                        <Li>Implementation of contract for service provision, settlement of usage fee according to service provision,
                            content provision, identity verification, purchase and fee payment, fee collection.</Li>
                        <Li>Member management<br/>
                            Identification according to the use of membership service, personal identification, prevention of fraudulent and
                            unauthorized use by bad members, confirmation of intent to join, restriction on subscription, record
                            preservation for dispute mediation, handling complaints, and delivery of notices.</Li>
                        <Li>Use for marketing and advertising<br/>
                            Development and specialization of new services, provision of services and advertisements according to
                            demographic characteristics, identification of access frequency, statistics on service use by members, delivery
                            of advertising information such as events. (Your personal information will not be provided to individuals or
                            organizations requesting advertisements.)</Li>
                    </ol>
                </Li>
            </ol>
            <H5>Article 2 (Processing and retention period of personal information)</H5>
            <ol>
                <Li>Personal information processed by the company is processed within the scope for the purpose of collection and use, and
                    the retention period stipulated by the Personal Information Protection Act and related laws is applied.</Li>
                <Li>The retention period of personal information processed on the company's homepage is as follows.
                    <ol type={"a"}>
                        <Li>Personal information to be deleted upon membership withdrawal
                            <ul>
                                <Li>Items to be deleted: Name, email, phone number (mobile phone number) provided by the member</Li>
                                <Li>Deletion period: Immediately after membership withdrawal</Li>
                            </ul>
                        </Li>
                        <Li>Preservation of personal information related to commercial transactions
                            <ul>
                                <Li>Retention items: transaction history</Li>
                                <Li>Retention basis: Commercial Act, Consumer Protection Act in Electronic Commerce, etc.</Li>
                                <Li>Retention period:
                                    <ul>
                                        <Li>Records on contract or subscription withdrawal: 5 years</Li>
                                        <Li>Records on payment and supply of goods: 5 years</Li>
                                        <Li>Records on consumer complaints or dispute handling: 3 years</Li>
                                    </ul>
                                </Li>
                            </ul>
                        </Li>
                        <Li>Records of access/use of the information system
                            <ul>
                                <Li>Retention items: Access/use records</Li>
                                <Li>Retention period: Records on service access and use: 5 years</Li>
                            </ul>
                        </Li>
                    </ol>
                </Li>
            </ol>
            <H5>Article 3 (Items of personal information to be processed)</H5>
            <ol>
                <Li>The purpose of processing personal information processed by the company is as follows.
                    <ol type={"a"}>
                        <Li>Items of personal information collected when signing up for membership<br/>When you sign up for the first time,
                            we collect the following information to identify members and provide optimized services.
                            <ul>
                                <Li>Required information: name, ID, company name, password, email, phone number (mobile phone number),
                                    business number</Li>
                            </ul>
                        </Li>
                        <Li>Items of information collected during the service use process, etc.<br/> The following information may be
                            generated and collected during service use or business processing.
                            <ul>
                                <Li>Service use records, access logs, cookies, access IP information, payment records, bad use records</Li>
                            </ul>
                        </Li>
                        <Li>Items of personal information collected for marketing and advertisement use<br/>The company collects the
                            following information for marketing and advertising purposes.
                            <ul>
                                <Li>Required information: Name, email, phone number (mobile phone number)</Li>
                            </ul>
                        </Li>
                        <Li>Collection method<br/>The company collects personal information in the following ways.
                            <ul>
                                <Li>Membership registration through the homepage, form, phone, consultation board, e-mail, event
                                    application, delivery request</Li>
                                <Li>Collection through generated information collection tool</Li>
                            </ul>
                        </Li>
                    </ol>
                </Li>
            </ol>
            <H5>Article 4 (Provision of personal information to third parties)</H5>
            <ol>
                <Li>The company processes the personal information of the information subject only within the scope specified in Article 1
                    (Purpose of processing personal information), and processes it beyond the scope of the original purpose without the
                    prior consent of the information subject, except in the following cases It is not provided to third parties.
                    <ol type={"a"}>
                        <Li>When obtaining separate consent from the information subject</Li>
                        <Li>If there are special provisions in the law</Li>
                        <Li>In cases where the data subject or legal representative is unable to express his/her intention or obtain prior
                            consent due to unknown address, etc., which is clearly recognized as necessary for the immediate benefit of the
                            life, body, or property of the information subject or a third party case</Li>
                        <Li>If personal information is used for purposes other than the intended purpose or if it is not provided to a third
                            party, the duties prescribed by other laws cannot be performed, and the protection committee has deliberated and
                            decided.</Li>
                        <Li>When it is necessary to provide information to foreign countries or international organizations for the
                            implementation of treaties and other international agreements</Li>
                        <Li>When it is necessary for the investigation of a crime and the filing and maintenance of a public
                            prosecution</Li>
                        <Li>When it is necessary for the court's trial work</Li>
                        <Li>When it is necessary for the execution of punishment, probation, and protective disposition</Li>
                    </ol>
                </Li>
            </ol>
            <H5>Article 5 Consignment of personal information processing</H5>
            <ol>
                <Li>1. The Company entrusts the processing of personal information as follows for smooth personal information processing.
                    <Table columns={delegateColumns}
                           dataSource={delegateData}
                           pagination={false}
                           style={{marginTop: "20px", marginBottom: "20px"}}
                           bordered/>
                </Li>
                <Li>When concluding a consignment contract, the company prohibits processing of personal information for purposes other than
                    the purpose of entrusted business pursuant to Article 26 of the 「Personal Information Protection Act」, takes technical
                    and managerial protection measures, restricts re-entrustment, manages and supervises consignees, and compensates for
                    damages, etc. The matters are specified in documents such as contracts, and we supervise whether the trustee handles
                    personal information safely.</Li>
                <Li>If the contents of the consignment work or the consignee are changed, we will disclose it through this personal
                    information processing policy without delay.</Li>
            </ol>
            <H5>Article 6 (Procedures and Methods for Destruction of Personal Information)</H5>
            <ol>
                <Li>The company destroys the personal information without delay when the personal information becomes unnecessary, such as
                    the expiration of the personal information retention period and the achievement of the purpose of processing.</Li>
                <Li>If personal information must be kept in accordance with other laws and regulations even though the retention period
                    agreed to by the information subject has elapsed or the purpose of processing has been achieved, the personal
                    information is moved to a separate database (DB) or stored Save by changing.</Li>
                <Li>The procedure and method of destroying personal information are as follows.
                    <ol type={"a"}>
                        <Li>Destruction procedure
                            <br/>
                            Unnecessary personal information and personal information files are handled as follows in accordance with the
                            internal policy procedure under the responsibility of the person in charge of personal information protection.
                            <ul>
                                <Li>Destruction of personal information<br/>
                                    Personal information whose retention period has elapsed is destroyed without delay from the end
                                    date.</Li>
                                <Li>Destruction of personal information files<br/>
                                    When the personal information file becomes unnecessary, such as the achievement of the purpose of
                                    processing the personal information file, the abolition of the relevant service, or the termination of
                                    the business, the personal information file is destroyed without delay from the date when the processing
                                    of personal information is deemed unnecessary.</Li>
                            </ul>
                        </Li>
                        <Li>Destruction method
                            <ul>
                                <Li>Information in electronic form uses a technical method that cannot reproduce records.</Li>
                                <Li>Personal information printed on paper is shredded with a shredder or destroyed by incineration.</Li>
                            </ul>
                        </Li>
                    </ol>
                </Li>
            </ol>
            <H5>Article 7 (Matters concerning the rights and obligations of data subject and legal representative and exercise method)</H5>
            <ol>
                <Li>Users and legal representatives can request access, correction, deletion, or suspension of processing of personal
                    information at any time. You can exercise your right.
                    <br/>※ Requests for viewing personal information of children under the age of 14 must be made directly by the legal
                    representative, and minor users over the age of 14 may exercise their rights regarding the user's personal information
                    either by themselves or through a legal representative. Might be.</Li>
                <Li>The exercise of rights pursuant to Paragraph 1 can be done in writing, e-mail, fax, etc. after filling out the form in
                    accordance with the [Public Notice on Personal Information Handling Methods Annex 8], and measures will be taken without
                    delay. I'll do it.</Li>
                <Li>If the information subject requests correction or deletion of personal information errors, the personal information will
                    not be used or provided until the correction or deletion is completed.</Li>
                <Li>The exercise of rights pursuant to Paragraph 1 can be done through an agent, such as a legal representative of the
                    information subject or a person who has been delegated. In this case, you must submit a power of attorney in accordance
                    with the [Appendix No. 11 of the Notice on Personal Information Processing Methods].</Li>
                <Li>Request for viewing and suspension of processing of personal information may be restricted according to Article 35
                    Paragraph 5 and Article 37 Paragraph 2 of the Personal Information Protection Act.</Li>
                <Li>Request for correction and deletion of personal information cannot be requested if the personal information is specified
                    as the subject of collection in other laws and regulations.</Li>
                <Li>Confirm whether the person who made the request, such as request for viewing, correction or deletion according to the
                    rights of the information subject, or request for suspension of processing, is the person or a legitimate agent.
                    <ul>
                        <Li>Present identification (resident registration card, driver's license, passport, etc.)</Li>
                        <Li>In the case of an agent, present identification and power of attorney to verify the agent</Li>
                        <Li>[Appendix No. 8 of Notice on Personal Information Handling Methods] Presentation of personal information access
                            request</Li>
                        <Li>[Attachment No. 11 of Notice on Personal Information Processing Methods] Presentation of power of attorney</Li>
                    </ul>
                </Li>
            </ol>
            <H5>Article 8 (Measures for Ensuring Safety of Personal Information)</H5>
            <P>In accordance with Article 29 of the 「Personal Information Protection Act」, the company's web service takes the following
                technical, administrative, and physical measures necessary to secure safety.</P>
            <ol>
                <Li>Establishment and implementation of internal management plan
                    <br/>The company establishes and implements an internal management plan in accordance with the ‘Standards for Ensuring
                    the Safety of Personal Information’.</Li>
                <Li>Minimization and training of personal information handling personnel
                    <br/>We are implementing measures to manage personal information by designating and minimizing the person in charge of
                    handling personal information.</Li>
                <Li>Restriction of access to personal information
                    <br/>We take necessary measures to control access to personal information by granting, changing, or canceling access
                    rights to the database system that handles personal information, and we control unauthorized access from outside using
                    an intrusion prevention system.</Li>
                <Li>Storage of access records and prevention of forgery/falsification
                    <br/>Records of access to the personal information processing system (web log, summary information, etc.) are stored and
                    managed for at least six months.</Li>
                <Li>Encryption of personal information
                    <br/>Users' personal information is encrypted and stored and managed. In addition, we use separate security functions
                    such as encrypting important data when storing and transmitting.</Li>
                <Li>Security program installation and periodic inspection/update
                    <br/>The company installs and periodically updates and inspects security programs to prevent leakage and damage of
                    personal information caused by hacking or computer viruses.</Li>
            </ol>
            <H5>Article 9 (Matters concerning the installation, operation and refusal of devices that automatically collect personal
                information)</H5>
            <ol>
                <Li>The company uses 'cookies' that store and retrieve usage information from time to time to provide personalized services
                    to users.</Li>
                <Li>Cookies are a small amount of information that the server used to operate the website sends to the user's computer
                    browser, and is also stored on the hard disk of the user's PC computer.
                    <ol type={"a"}>
                        <Li>Purpose of using cookies: Cookies are used to provide optimized information to users by identifying the types of
                            visits and use of each service and website visited by users, popular search words, security access, etc.</Li>
                        <Li>Installation/Operation and Rejection of Cookies: You can refuse to save cookies by setting options in the
                            Tool &gt; Internet Options &gt; Personal Information menu at the top of the web browser.</Li>
                        <Li>If you refuse to save cookies, you may experience difficulties in using customized services.</Li>
                    </ol>
                </Li>
            </ol>
            <H5>Article 10 (Matters Regarding Collection, Use, Provision and Rejection of Behavioral Information)</H5>
            <ol>
                <Li>The company collects and uses behavioral information in order to provide customized services and benefits optimized to
                    information subjects, online customized advertisements, etc. during the service use process.</Li>
                <Li>The company collects behavioral information as follows.
                    <Table columns={collectTableColumns}
                           dataSource={collectTableData}
                           pagination={false}
                           style={{marginTop: "20px", marginBottom: "20px"}}
                           bordered/>
                </Li>
                <Li>The company collects only the minimum behavioral information necessary for online customized advertisements, and is
                    concerned about clearly infringing on individual rights, interests, or privacy, such as thoughts, beliefs, family and
                    relatives, academic background, medical history, and other social activity careers. We do not collect sensitive
                    behavioral information.</Li>
                <Li>The data subject can block/allow online customized advertisements collectively by changing the cookie settings of the
                    web browser. However, changing cookie settings may affect the use of some services, such as automatic website login.
                    ▶ Block/allow customized ads through web browser
                    <ol type={"a"}>
                        <Li>① Internet Explorer (Internet Explorer 11 for Windows 10)
                            <ul>
                                <Li>In Internet Explorer, select the Tools button, then select Internet Options</Li>
                                <Li>Select the Privacy tab, select Advanced under Settings, then Block or Allow Cookies</Li>
                            </ul>
                        </Li>
                        <Li>Microsoft Edge
                            <ul>
                                <Li>In the upper right corner of Edge, select ‘… ’, and then click Settings.</Li>
                                <Li>After clicking ‘Personal information, search and services’ on the left side of the settings page, select
                                    whether or not to ‘tracking prevention’ and the level in the ‘tracking prevention’ section.</Li>
                                <Li>Choose whether to ‘Always use “Strict” Tracking Protection when browsing InPrivate’.</Li>
                                <Li>Select whether to ‘Send Do Not Track Request’ in the 「Personal Information」 section below.</Li>
                            </ul>
                        </Li>
                        <Li>Chrome browser
                            <ul>
                                <Li>In Chrome, click the top right ‘⋮’ sign (Customize and control chrome), then click Show settings.</Li>
                                <Li>Click ‘Show advanced settings’ at the bottom of the Settings page and click Content settings in the
                                    “Privacy” section.</Li>
                                <Li>In the Cookies section, check the box for ‘Block third-party cookies and site data’.</Li>
                            </ul>
                        </Li>
                    </ol>
                </Li>
            </ol>
            <H5>Article 11 (Matters on Person in Charge of Personal Information Protection)</H5>
            <ol>
                <Li>1. In order to protect personal information and handle complaints related to personal information, the company appoints
                    a person in charge of personal information protection and a person in charge of personal information protection by field
                    as follows. (Person in charge of personal information protection pursuant to Article 31 Paragraph 1 of the Personal
                    Information Protection Act)
                    <Table columns={managerTableColumns}
                           dataSource={managerTableData}
                           pagination={false}
                           bordered/>
                </Li>
            </ol>
            <H5>Article 12 (Department that receives and handles requests for access to personal information)</H5>
            <ol>
                <Li>The information subject may request to view personal information in accordance with Article 35 of the Personal
                    Information Protection Act to the following departments. The company will make every effort to promptly process the
                    personal information access request of the information subject.
                    <ul>
                        <Li>Personal information viewing request department
                            <ul>
                                <Li>Department name: Data management team</Li>
                                <Li>Person in charge: Minji Kim</Li>
                                <Li>Contact: Phone 010-2476-9700 / Email: business@thirdworks.co.kr</Li>
                            </ul>
                        </Li>
                    </ul>
                </Li>
                <Li>The information subject may request access to personal information through the ‘Comprehensive Personal Information
                    Protection Portal’ (www.privacy.go.kr) of the Personal Information Protection Commission, in addition to the reception
                    and processing department of the request for reading in Paragraph 1.
                    <ul>
                        <Li>Comprehensive portal for personal information protection by the Personal Information Protection Commission →
                            Personal information complaints → Requests to view personal information (requires real name authentication
                            through public i-PIN)</Li>
                    </ul>
                </Li>
            </ol>
            <H5>Article 13 (Method of Remedy for Infringement of Rights and Interests of Information Subjects)</H5>
            <ol>
                <Li>The information subject may inquire about damage relief and consultation for personal information infringement to the
                    following organizations. The organizations below are separate organizations from the company. If you are not satisfied
                    with the company's own personal information complaint handling and damage relief results, or if you need more detailed
                    assistance, please contact them.</Li>
                <ul>
                    <Li>Personal Information Infringement Report Center (operated by Korea Internet & Security Agency)
                        <ul>
                            <Li>Services: Reporting personal information infringement, applying for consultation</Li>
                            <Li>Website: privacy.kisa.or.kr</Li>
                            <Li>Phone: (without area code) 118</Li>
                            <Li>Address: (58324) Personal Information Infringement Report Center, 3rd floor ,9 Jinheung-gil, Naju-si,
                                Jeollanam-do, Korea (301-2 Bitgaram-dong) </Li>
                        </ul>
                    </Li>
                    <Li>Personal Information Dispute Mediation Committee
                        <ul>
                            <Li>Services: personal information dispute mediation application, collective dispute mediation (civil
                                settlement)</Li>
                            <Li>Website: www.kopico.go.kr</Li>
                            <Li>Phone: 1833-6972</Li>
                            <Li>Address: (03171) Personal Information Dispute Mediation Committee, 12th Floor, Government Complex Seoul,
                                209, Sejong-daero, Jongno-gu, Seoul</Li>
                        </ul>
                    </Li>
                    <Li>Supreme Prosecutor’s Office, Cyber Investigation Division
                        <ul>
                            <Li>☎1301 (www.spo.go.kr, cid@spo.go.kr)</Li>
                        </ul>
                    </Li>
                    <Li>National Police Agency, Cyber Terror Response Center
                        <ul>
                            <Li>☎182 (cyberbureau.police.go.kr)</Li>
                        </ul>
                    </Li>
                </ul>
                <Li>In addition, a person whose rights or interests have been violated due to a disposition or omission taken by the head of
                    a public institution in response to the information subject's request for access, correction, deletion, suspension of
                    processing of personal information, etc. can be charged. Central Administrative Appeals Commission (without an area
                    code) 110 (www.simpan.go.kr)</Li>
            </ol>
            <H5>Article 14 (Matters Regarding Changes to Personal Information Handling Policy)</H5>
            <ol>
                <Li>This policy will be effective from April 1, 2023.</Li>
                {/*<Li>You can check the previous personal information policy below.</Li>*/}
            </ol>
            <p>April. 1, 2023 - Application Guidelines</p>
            <div style={{marginBottom: "180px"}}/>

        </ContentContainer>
    );
};

export default PrivacyPolicyEnFragment;