import styled from "styled-components";
import CommonComponent, {CommonProps} from "./CommonComponent";
import {Colors} from "./Styles";

interface ButtonProps extends CommonProps {

}

const Button = styled(CommonComponent)<ButtonProps>`
  background-color: ${({
                         disabled,
                         backgroundColor,
                         backgroundOnDisabled
                       }) => disabled ? backgroundOnDisabled : (backgroundColor || Colors.MAIN)};
  width: ${({width}) => width || "fit-content"};
  height: ${({height}) => height || "52px"};
  display: ${({display, visible}) => visible ? (display || "flex") : "none"};
  font-size: ${({fontSize}) => fontSize || "20px"};
  font-weight: ${({fontWeight}) => fontWeight || 700};
  color: ${({color, disabled, colorOnDisabled}) => disabled ? colorOnDisabled : (color || Colors.WHITE)};
  text-align: ${({textAlign}) => textAlign || "center"};
  align-items: ${({alignItems}) => alignItems || "center"};
  justify-content: ${({justifyContent}) => justifyContent || "center"};
  border-radius: ${({borderRadius}) => borderRadius || "12px"};
  cursor: pointer;
`;

export default Button;