enum AuthType {
    PREMIUM_ACTIVATED = "auths/PREMIUM_ACTIVATED",
    REFRESH_PAYMENT_STATE = "auths/REFRESH_PAYMENT_STATE"
}

interface AuthState {
    premiumActivated: boolean;
    paymentState: number;
}

interface SetPremiumActivatedAction {
    type: AuthType.PREMIUM_ACTIVATED,
    premiumActivated: boolean
}

interface RefreshPaymentStateAction {
    type: AuthType.REFRESH_PAYMENT_STATE,
    paymentState: number
}


type AuthAction =
    | SetPremiumActivatedAction
    | RefreshPaymentStateAction


const initialState: AuthState = {
    premiumActivated: false,
    paymentState: 0
};

export function setPremiumActivated(activated: boolean) {
    return {
        type: AuthType.PREMIUM_ACTIVATED,
        premiumActivated: activated,
    };
}

export function refreshPaymentState() {
    return {
        type: AuthType.REFRESH_PAYMENT_STATE,
        paymentState: 1,
    };
}

function auths(
    state: AuthState = initialState,
    action: AuthAction,
): AuthState {
    switch (action.type) {
        case AuthType.PREMIUM_ACTIVATED:
            return {
                ...state,
                premiumActivated: action.premiumActivated,
            };
        case AuthType.REFRESH_PAYMENT_STATE:
            return {
                ...state,
                paymentState: state.paymentState + action.paymentState,
            };
        default:
            return state;
    }
}

export default auths;