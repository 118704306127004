import React from "react";
import {ContentContainer} from "../styledcomponents/Containers";
import styled from "styled-components";
import {ColumnsType} from "antd/es/table";
import {Table} from "antd";

const TermsOfServiceEnFragment = () => {

    const Li = styled.li`
      margin-top: 6px;
      font-size: 16px;
      line-height: 1.7;
    `
    const P = styled.p`
      font-size: 16px;
      line-height: 1.7;
    `
    const H4 = styled.h4`
      font-size: 24px;
      margin-top: 60px;
    `
    const H5 = styled.h5`
      font-size: 20px;
      margin-top: 40px;
    `

    interface DataType {
        key: string;
        service: string;
        content: string;
        freeUser: string;
        paymentUser: string;
    }

    const columns: ColumnsType<DataType> = [
        {
            title: 'No',
            align: "center",
            dataIndex: 'key',
            onCell: (_, index) => {
                if (index === 1 || index === 4) {
                    return {rowSpan: 3};
                }
                if (index === 2 || index === 3 || index === 5 || index === 6) {
                    return {rowSpan: 0};
                }

                return {};
            },
        },
        {
            title: 'Service',
            align: "center",
            dataIndex: 'service',
            onCell: (_, index) => {
                if (index === 0 || index === 7) {
                    return {colSpan: 2};
                }
                if (index === 1 || index === 4) {
                    return {rowSpan: 3}
                }
                if (index === 2 || index === 3 || index === 5 || index === 6) {
                    return {rowSpan: 0}
                }
                return {};
            },
        },
        {
            title: 'Content',
            align: "center",
            dataIndex: 'content',
            onCell: (_, index) => {
                if (index === 0 || index === 7) {
                    return {colSpan: 0};
                }
                return {};
            },
        },
        {
            title: 'Free',
            dataIndex: 'freeUser',
            align: "center"
        },
        {
            title: 'Paid',
            dataIndex: 'paymentUser',
            align: "center"
        }
    ];

    const data: DataType[] = [
        {
            key: '0',
            service: 'User Definition',
            content: "",
            freeUser: "✓",
            paymentUser: "✓"
        },
        {
            key: '1',
            service: "Global Sourcing Intelligence",
            content: 'Statistical information service on imported goods (import volume, import ranking, etc.)',
            freeUser: "✓",
            paymentUser: "✓"
        },
        {
            key: '12',
            service: "",
            content: 'Import customs clearance statistical information service (customs clearance price, customs clearance cycle, customs clearance date, etc.)',
            freeUser: "",
            paymentUser: "✓"
        },
        {
            key: '13',
            service: "",
            content: 'Import transaction statistical information service (transaction target, transaction item, market share, etc.)',
            freeUser: "",
            paymentUser: "✓"
        },
        {
            key: '2',
            service: "Income Management Solution",
            content: 'Searching for imported products and trading partners and providing information (product sourcing)',
            freeUser: "✓",
            paymentUser: "✓"
        },
        {
            key: '22',
            service: "",
            content: 'Product contract requirement information provision service (price, packaging type, certificate, etc.)',
            freeUser: "✓",
            paymentUser: "✓"
        },
        {
            key: '23',
            service: "",
            content: 'Service for providing information on import procedure requirements (import procedure, document preparation guide, etc.)',
            freeUser: "",
            paymentUser: "✓"
        },
        {
            key: '3',
            service: 'All services developed by other companies or provided through cooperation agreements with other companies',
            content: "",
            freeUser: "",
            paymentUser: "✓"
        },
    ];

    return (
        <ContentContainer width={"100%"}
                          maxWidth={"800px"}
                          alignItems={"flex-start"}
                          style={{textAlign: "start"}}
                          paddingLeft={"20px"}
                          paddingRight={"20px"}>
            <h3 style={{marginTop: "80px", alignSelf: "center"}}>THIRD WORKS TERMS OF USE</h3>
            <H4>Chapter 1: General Provisions</H4>
            <H5>Article 1 (Purpose)</H5>
            <P>
                These terms and conditions govern the rights and obligations of the company and users regarding the use of the THIRD WORKS
                service (<a href={"http://www.thirdworks.co.kr"}>https://www.thirdworks.co.kr</a>, hereinafter referred to as the “service”)
                provided by THIRD WORKS Inc. (hereinafter referred to as the “Company”). The purpose is to stipulate matters and other
                necessary matters.
            </P>
            <H5>Article 2 (Definition of Terms)</H5>
            <P>The definitions of terms used in these Terms and Conditions are as follows.</P>
            <ol type={"1"}>
                <Li>User: Refers to “members” and “non-members” who use the services provided by the company as stipulated in these terms
                    and conditions.</Li>
                <Li>Member: A person who agrees to these Terms and Conditions and joins as a member to continuously receive the services
                    provided by the company.
                    <ol type={"a"}>
                        <Li>Basic member (free): A person who uses limited services after registering as a member in accordance with these
                            terms and conditions.</Li>
                        <Li>Corporate manager (paid): A person who has been given the managerial authority for all services used by the
                            company after obtaining the final approval from the person in charge of the company after signing up as a member
                            by providing the company information requested by the company.</Li>
                        <Li> Corporate member (paid): Members who use the service after being granted the right to use by the company
                            manager to which the member belongs.</Li>
                    </ol>
                </Li>
                <Li>Non-member: A person who uses the limited services provided by the company without signing up as a member. In principle,
                    this agreement does not apply. You may be restricted from using it according to the terms of use, and you may be subject
                    to sanctions under the relevant laws and regulations.
                </Li>
                <Li>ID: A business email address selected by the user and approved by the company to use the service provided by the
                    company. As a rule, members use one ID.</Li>
                <Li>Password: A combination of letters, numbers, and special characters used to verify the identity of the ID</Li>
                <Li>Global Sourcing Intelligence: Refers to the yearly and import volume of imported goods by country, import ranking,
                    imported items by company, market share, transaction relationship analysis, and all accompanying market analysis
                    services.</Li>
                <Li>Import management solution: Based on Global Sourcing Intelligence data, it refers to an electronic system for managing
                    import business for companies that provides import information such as imported products, business partners, contracts
                    and import requirements, and follows import procedures.</Li>
                <Li>Withdrawal of account: This refers to the act of a member terminating the use contract and giving up membership.</Li>
            </ol>
            <H5>Article 3 (Effect and Change of Terms and Conditions)</H5>
            <ol type={"1"}>
                <Li>These terms and conditions become effective when posted on the service website.</Li>
                <Li>The company may revise these terms and conditions to the extent that it does not violate relevant laws such as the “Act
                    on the Regulation of Terms and Conditions” and the “Act on Promotion of Information and Communications Network
                    Utilization and Information Protection, etc.”, and when revising the terms and conditions, specify the reason and the
                    date and notify by the method of paragraph 1 from 7 days before the application date to the day before the application
                    date.
                </Li>
                <Li>If the user uses the service after the application date of Paragraph 2, the user is deemed to have agreed to the revised
                    terms and conditions, and the user may terminate the use contract if he or she does not agree to the revised terms and
                    conditions.</Li>
            </ol>
            <H5>Article 4 (Rules other than Terms and Conditions)</H5>
            <ol type={"1"}>
                <Li>The company may have separate terms and conditions and policies for paid services and individual services, and if the
                    content conflicts with these terms and conditions, the separately set terms and policies take precedence.</Li>
                <Li>Regarding the content or interpretation not specified in these Terms and Conditions, related laws or general commercial
                    practices take precedence, and exemption cannot be requested based on the Terms of Use.</Li>
            </ol>
            <H4>Chapter 2 Service Use Agreement</H4>
            <H5>Article 5 (Conclusion of the contract of use)</H5>
            <ol type={"1"}>
                <Li>The use contract is concluded when a person who wants to become a member (hereinafter referred to as “subscription
                    applicant”) agrees to the terms and conditions, then applies for membership, and the company approves the
                    application.</Li>
                <Li>Subscription applicants must provide their true information when applying for subscription, and if they use the personal
                    information of others to sign a service contract with the company, they will not be protected for any reason, and in
                    some cases punished by related laws. You can receive it.</Li>
                <Li>In addition, the company can verify the identity of the information provided by the applicant when signing up for
                    membership, and the applicant must cooperate.</Li>
            </ol>
            <H5>Article 6 (Approval and Restriction on Application for Use)</H5>
            <ol type={"1"}>
                <Li>The company may not approve the application for any of the following cases or may terminate the use contract afterwards.
                    <ol type={"a"}>
                        <Li>If the applicant for membership has previously lost user qualification under these terms and conditions,
                            however, exceptions are made when one year has elapsed since the loss of user qualification and the company has
                            obtained approval for user re-registration.</Li>
                        <Li>In the case of falsely providing essential information and attached documents when signing up for membership,
                            such as using the name of a third party or corporate name, or intentionally omitting or misunderstanding the
                            application</Li>
                        <Li>When applying for the purpose of hindering social stability and order or morals</Li>
                        <Li>If it is significantly difficult to conclude a use contract due to a lack of service-related facilities or
                            technical failures</Li>
                        <Li>If the applicant's request is for the purpose of causing a significant hindrance to the company's business
                            performance</Li>
                        <Li>If it is reasonably judged that the conclusion of other contracts for use is in violation of related laws or
                            these terms and conditions</Li>
                        <Li>Even after the conclusion of the contract of use, the company may suspend or restrict the use of the service if
                            it finds facts falling under each subparagraph of Article 6, and in some cases may terminate the contract of use
                            and cancel membership.</Li>
                    </ol>
                </Li>
            </ol>
            <H5>Article 7 (Change of member information)</H5>
            <ol type={"1"}>
                <Li>Members can view and modify their personal information at any time through the account information management screen.
                    However, the company name, ID, etc. necessary for the company's service management can be modified by requesting the
                    company through a separate e-mail or other method.</Li>
                <Li>Members must make corrections online or notify the company of the changes by e-mail or other methods if the information
                    entered at the time of membership application is changed.</Li>
                <Li>The company is not responsible for any disadvantages caused by not notifying the company of the changes in Paragraph
                    2.</Li>
            </ol>
            <H5>Article 8 (Protection of Personal Information)</H5>
            <ol type={"1"}>
                <Li>The company strives to protect the personal information collected by the company in relation to the service as
                    stipulated by the relevant laws and regulations, and the protection and use of personal information is in accordance
                    with the relevant laws and the company's personal information processing policy. However, the company's privacy policy
                    does not apply to linked services other than those provided by the company.</Li>
                <Li>The company may provide user information to a third party within the scope permitted by law in the following cases.
                    <ol type={"a"}>
                        <Li>When a “user” is requested to provide information from an investigative agency or other government agency in
                            violation of relevant laws while using the “service”</Li>
                        <Li>When it is necessary for information protection tasks such as checking fraudulent acts, including violations of
                            the law by “users” and violations of these Terms of Service</Li>
                        <Li>When required by other laws</Li>
                    </ol>
                </Li>
            </ol>
            <H5>Article 9 (Cancellation/Termination of Use Agreement)</H5>
            <ol type={"1"}>
                <Li>The user may terminate the use contract at any time by withdrawing from the website, notifying the intention to
                    terminate by e-mail, telephone, fax, or other means.</Li>
                <Li>The company may terminate the use contract for any of the following reasons and may restrict re-registration within a
                    certain period of time. In this case, the company may give the user an opportunity to state their opinion on the reason
                    for cancellation in advance.
                    <ol type={"a"}>
                        <Li>If the paid service fee is not paid within the set period</Li>
                        <Li>In the event that a “user” registers a post stipulated in the Terms of Use or attempts or attempts to trade
                            goods that violate other public order and morals</Li>
                        <Li>When applying by stealing someone else's name or by stealing another company's business registration number</Li>
                        <Li>In case of violation of related laws and terms and conditions and a request for correction more than once and
                            not responding to it, resulting in a significant hindrance to the company's business technology</Li>
                        <Li>Other cases where the company judges that it is inappropriate as a user</Li>
                    </ol>
                </Li>
            </ol>
            <H4>Chapter 3 Obligations of the Parties</H4>
            <H5>Article 10 (Obligations of “Company”)</H5>
            <ol type={"1"}>
                <Li>The company faithfully complies with the obligations stipulated by the relevant laws and these terms and conditions in
                    good faith.</Li>
                <Li>The company must have a security system to protect personal information (including credit information) so that users can
                    use the service safely, and disclose and comply with the privacy policy.</Li>
                <Li>If the company recognizes that the opinions or complaints raised by users in relation to the use of the service are
                    legitimate, it must deal with them.</Li>
                <Li>For opinions or complaints raised by users, the bulletin board is used or the process and results are delivered to
                    members through e-mail.</Li>
            </ol>
            <H5>Article 11 (Obligations of “Users”)</H5>
            <ol type={"1"}>
                <Li>Users cannot engage in any commercial activities by using the service without prior consent from the company.</Li>
                <Li>Users cannot copy, reproduce, change, translate, publish, broadcast, or otherwise use the information obtained by using
                    the service or provide it to others without prior consent from the company.</Li>
                <Li>The user shall not engage in any of the following acts in relation to the use of the service, and if it falls under each
                    of the subparagraphs, the company may impose restrictions on the use of the service and membership qualifications to the
                    user.
                    <ol type={"a"}>
                        <Li>An act of registering false information when applying for or changing membership</Li>
                        <Li>Acts that violate personal rights, such as defaming or insulting the company and other third parties</Li>
                        <Li>In case of violating the intellectual property rights of the company and other third parties or interfering with
                            the business</Li>
                        <Li>Stealing another person's member ID, password, etc., or stealing another person's name and credit
                            information</Li>
                        <Li>An act of duplicating, processing or otherwise transforming services through computer programs, solutions, etc.
                            other than those provided by the company</Li>
                        <Li>An act of incubating the server or interfering with the normal service of the company through abnormal access or
                            programs for the purpose of causing direct or indirect damage to the company</Li>
                        <Li>Collecting or storing other users' personal information without their consent</Li>
                        <Li>Transmitting or posting information that is prohibited from being transmitted or posted by related laws or legal
                            relationships, obscene or violent messages, images, voices, or other information that goes against public order
                            and morals</Li>
                        <Li>An act of allowing a third party other than yourself to use your member ID</Li>
                        <Li>Changing information posted on the company website or abusing the paid service system</Li>
                        <Li>Other illegal or unfair acts</Li>
                    </ol>
                </Li>
            </ol>
            <H4>Chapter 4 Use of Services</H4>
            <H5>Article 12 (Service contents, etc.)</H5>
            <ol type={"1"}>
                <Li>1. The company provides the following services to users.
                    <ol type={"a"}>
                        <Li>Global Sourcing Intelligence
                            <ul>
                                <Li>Statistical information service on imported goods (import volume, import ranking, etc.)</Li>
                                <Li>Import customs clearance statistical information service (customs clearance price, customs clearance
                                    cycle, customs clearance date, etc.)</Li>
                                <Li>Import transaction statistical information service (transaction target, transaction item, market share,
                                    etc.)</Li>
                            </ul>
                        </Li>
                        <Li>Income Management Solution
                            <ul>
                                <Li>Searching for imported products and trading partners and providing information (product sourcing)</Li>
                                <Li>Product contract requirement information provision service (price, packaging type, certificate,
                                    etc.)</Li>
                                <Li>Service for providing information on import procedure requirements (import procedure, document
                                    preparation guide, etc.)</Li>
                            </ul>
                        </Li>
                        <Li>All services developed by other companies or provided through cooperation agreements with other companies</Li>
                    </ol>
                </Li>
                <Li>In principle, the service hours are 24 hours a day, 7 days a week, unless there is a special obstacle to the company's
                    business or technology.</Li>
                <Li>However, it may be suspended on the day or time determined by the company as necessary for periodic inspection, etc.,
                    notified in advance, and in case of maintenance inspection, replacement, breakdown of information communication
                    facilities, etc., communication interruption, or significant operational reasons. The provision of services may be
                    temporarily suspended.</Li>
            </ol>
            <H5>Article 13 (Change of Service)</H5>
            <ol type={"1"}>
                <Li>The company may add, change, or delete the contents of the service if necessary, and the matters necessary for use
                    according to this shall be determined by the company and posted in the user guide or separately announced.</Li>
            </ol>
            <H5>Article 14 (Management of Posts)</H5>
            <ol>
                <Li>Members have the right and responsibility for all materials, such as posts written or posted by users. Therefore, all
                    civil and criminal responsibilities arising from posts posted by users lie with the users themselves.</Li>
                <Li>Users cannot post or transmit posts that fall under any of the following subparagraphs, and the company may delete,
                    temporarily take action, move, or refuse to register posts that exist within the service if it is determined that they
                    fall under any of the following subparagraphs.
                    <ol type={"a"}>
                        <Li>If the content slanders or damages the reputation of other users or third parties</Li>
                        <Li>If the content slanders or damages the reputation of other users or third parties</Li>
                        <Li>In the case of content that is recognized as being connected to a criminal act</Li>
                        <Li>If the content infringes the rights of others, such as the copyright of the company or a third party</Li>
                        <Li>Causing political, religious, or social disputes for the purpose of violating laws or violating the rights of
                            others</Li>
                        <Li>In the case of posting unauthorized advertisements or promotional materials for profit or non-profit
                            purposes</Li>
                        <Li>If the content was created by stealing other people's personal information, or if the information entered by
                            others was forged without permission</Li>
                        <Li>If the post was posted by a member who has withdrawn</Li>
                        <Li>If the same content is duplicated or repeatedly posted, which goes against the purpose of the service</Li>
                        <Li>If the content is in violation of other related laws and terms and conditions</Li>
                    </ol>
                </Li>
            </ol>
            <H5>Article 15 (Provision of Information and Posting of Advertisements)</H5>
            <ol>
                <Li>The company may place advertisements within the service in relation to the operation of the service. In addition,
                    advertising information can be transmitted by e-mail, text service (LMS/SMS), etc. only to members who have agreed to
                    receive it. In this case, the member can reject the reception at any time, and the company does not send advertising
                    information when the member rejects the reception.</Li>
                <Li>You may be connected to advertisements or services provided by others through banners or links among services provided
                    by the company.</Li>
                <Li>In case of being linked to advertisements or services provided by others in accordance with Paragraph 2, the service
                    provided in that area is not the company's service area, so the company does not guarantee reliability, stability, etc.
                    The company is not responsible for either.</Li>
            </ol>
            <H5>Article 16 (Attribution of Copyright, etc.)</H5>
            <ol>
                <Li>Intellectual property rights and other rights related to the services provided by the company and necessary software,
                    images, marks, logos, designs, service names, information and trademarks are owned by the company.</Li>
                <Li>Users may modify, lend, lend, sell, distribute, produce, transfer, relicense, establish security rights, or commercially
                    use all or part of the rights stipulated in Paragraph 1, except when the company expressly approves it. You cannot use
                    it, and you cannot allow a third party to do so.</Li>
                <Li>If a user violates paragraph 2, the user shall take civil and criminal responsibilities to the company.</Li>
            </ol>
            <H4>Chapter 5 Use of Paid Services</H4>
            <H5>Article 17 (Paid Membership Subscription)</H5>
            <ol>
                <Li>A member who wants to become a “paid member” can apply for membership by filling in the necessary information according
                    to the procedure set by the company and agreeing to these terms and conditions, and the company approves the application
                    for membership if there is no reason for disqualification.</Li>
                <Li>The company may validate the information provided by the member during the application process in Paragraph 1.</Li>
                <Li>If a “paid member” illegally uses the benefits of a paid member in a way that does not conform to the purpose of
                    operation, the company may restrict the provision of benefits to the member or cancel the membership.</Li>
            </ol>
            <H5>Article 18 (Paid member usage period)</H5>
            <ol>
                <Li>“Paid member” can use the paid member service immediately after signing up.</Li>
                <Li>The end date of the period of use of “paid member” on a monthly basis is the day on which 30 days elapse from the date
                    of subscription (D) (D+30), and the end date of the period of use of “paid member” on a yearly basis is 365 days after
                    the date of sign-up (D) It is the day (D+365).</Li>
                <Li>If the “Paid Member” agrees to automatic payment of usage fees and the payment is completed, the monthly “Paid Member”
                    usage period is renewed for 30 days, including the automatic payment date, and the annual “Paid Member” usage period is
                    renewed for 365 days.</Li>
                <Li>Even if the “paid member” agrees to the automatic payment of usage fees, the usage period will not be renewed unless the
                    payment is completed.</Li>
            </ol>
            <H5>Article 19 (Contents of Paid Service)</H5>
            <ol>
                <Li>1. The company provides “paid member” benefits to “paid members” who have paid the usage fee, and notifies the details
                    of “paid member” benefits through the website operated by the company(<a
                        href={"https://www.thirdworks.co.kr"}>https://www.thirdworks.co.kr</a>). However, the contents and method of
                    providing benefits may change according to the company's policy.</Li>
            </ol>
            <Table columns={columns}
                   dataSource={data}
                   pagination={false}
                   bordered/>
            <H5>Article 20 (Service usage fee)</H5>
            <ol>
                <Li>Service usage fee is a fee paid to use paid services among the services provided to members, and may be applied
                    differently depending on the type of member, period of use, type and amount of information provided, etc. according to
                    the company's fee policy.</Li>
                <Li>Charges may be changed according to the company's charge policy or revision of government notices, etc. In this case,
                    notice may be made on each service screen for 15 days before the change.</Li>
                <Li>The company may reduce or exempt the usage fee and grant other benefits to members according to the conditions set forth
                    separately, such as promotions.</Li>
            </ol>
            {/*<H5>Article 21 (Regular payment of usage fees)</H5>*/}
            {/*<ol>*/}
            {/*    <Li>"Paid member" agrees to automatic regular payment of usage fee based on the payment information provided by him/her*/}
            {/*        after 12:00 p.m.</Li>*/}
            {/*    <Li>“Paid members” may apply for cancellation of regular payment on My Page of the website up to 1 day before the scheduled*/}
            {/*        regular payment date.</Li>*/}
            {/*    <Li>"Paid member" must keep the personal information provided to the company up-to-date in order to receive "paid member"*/}
            {/*        benefits and make regular payment of usage fees.</Li>*/}
            {/*    <Li>The company is not responsible for any disadvantages caused by “paid members” violating the provisions of Paragraph*/}
            {/*        3.</Li>*/}
            {/*    <Li>The company must notify paid members of the payment of usage fees, and issue payment receipt details in writing or*/}
            {/*        through the computer network. (Notification on My Page of the website)</Li>*/}
            {/*</ol>*/}
            <H5>Article 22 (Refund Policy)</H5>
            <ol>
                <Li>The company refunds the service fee within 7 days only if the “paid member” has not used the benefits of the “paid
                    member” more than twice after subscribing to the “paid service” (based on the paid member service inquiry record).</Li>
                {/*<Li>When a “Paid Member” cancels regular payment during the “Paid Member” service period, the “Paid Member” service is*/}
                {/*    provided until the period of use expires. (Non-refundable for the period from the time of application for cancellation*/}
                {/*    of regular payment to the expiration of the period of use)</Li>*/}
            </ol>
            <H5>Article 23 (Suspension of Provision of Paid Member Benefits)</H5>
            <ol>
                <Li>If the “paid member” does not pay the usage fee, the company will immediately stop providing benefits to the “paid
                    member”. However, if the “paid member” pays the usage fee, the “paid member” benefits will be provided again from that
                    day.</Li>
                <Li>The company may stop providing benefits to “paid members” due to changes in the business environment and business
                    policies. In this case, it must be notified to “paid members” through the media operated by the company at least 30 days
                    prior to the suspension date.</Li>
            </ol>
            <H4>Chapter 6 Compensation for damages and disclaimer, etc.</H4>
            <H5>Article 24 (Prohibition of Transfer, etc.)</H5>
            <ol>
                <Li>Users cannot transfer or give the right to use the service to others or use it for the purpose of pledge. However,
                    exceptions are made when prior approval has been obtained from the company as stipulated in each subparagraph below.
                    <ol type={"a"}>
                        <Li>In case the representative of a private enterprise dies: the heir who succeeds the position of the
                            representative</Li>
                        <Li>In case of change of company type: company after change</Li>
                        <Li>In case of merger between corporations: Surviving company</Li>
                        <Li>In case of merger of two or more corporations into one corporation: New corporation</Li>
                        <Li>In case one corporation is divided into two or more corporations and the divided corporation acquires the right
                            to use: The divided corporation</Li>
                    </ol>
                </Li>
                <Li>2. In the case of an exception under Paragraph 1, you must apply for succession to the company with documents proving
                    the change in status succession within one month, and the company will review all matters and notify you of the
                    succession decision.</Li>
            </ol>
            <H5>Article 25 (Compensation for Damages)</H5>
            <ol>
                <Li>The company is responsible for compensating for damages to members caused by intentional or gross negligence of the
                    company in relation to the service.</Li>
                <Li>In the following cases, the company shall not be liable for damages.
                    <ol type={"a"}>
                        <Li>In case of service instability or suspension of service due to prior notice or regular inspection for service
                            improvement, etc.</Li>
                        <Li>In case the damage caused is due to a reason attributable to the user</Li>
                        <Li>In case of damages or service delays due to reasons beyond the scope of control of the “Institute”, such as
                            natural disasters such as fires and floods, strikes, disruptions in power supply and demand, etc.</Li>
                        <Li>The claim for damages under Paragraph 1 must be made in writing, stating the reason for the claim, the amount of
                            the claim, and the basis for calculation.</Li>
                        <Li>We are not responsible for compensation for service use obstacles and use restrictions caused by the user's
                            intention or negligence.</Li>
                    </ol>
                </Li>
            </ol>
            <H5>Article 26 (Exemption clause)</H5>
            <ol>
                <Li>The company is not responsible for any problems caused by the following reasons.
                    <ol type={"a"}>
                        <Li>Responsibility for information and data obtained by the user using the service</Li>
                        <Li>Responsibility for any other content such as reliability, accuracy, etc. of information, data, facts posted by
                            users on the service</Li>
                        <Li>In the case of necessary post-processing, such as transaction requirements between "users" through the service
                            provided by the company, transaction execution details, delivery of goods, withdrawal or return of subscription,
                            dispute resolution due to defective goods</Li>
                        <Li>In the event of an infringement accident due to negligent security management by the "user"</Li>
                        <Li>Responsibility for damages caused by the user's failure to obtain the expected benefits from the services
                            provided by the company or by selection or use of service data</Li>
                        <Li>Mental and financial damage suffered by other members while using the services provided by the company</Li>
                        <Li>There is a possibility that the personal information included in the contents posted voluntarily and openly by
                            users may be collected and used by others, and responsibility for such risks</Li>
                        <Li>All responsibilities arising from other sites that users visit through the company's website</Li>
                        <Li>Responsibility for damages incurred to members in connection with the use of the services provided by the
                            company without intention or gross negligence on the part of the company</Li>
                    </ol>
                </Li>
            </ol>
            <H5>Article 27 (Disclaimer of Representation and Warranty)</H5>
            <ol>
                <Li>The company only provides an online trading place for mutual transactions between "users", so it does not represent
                    "users" who want to sell or purchase products. In addition, any act of service provided by the company is not regarded
                    as an act on behalf of a specific member, company or institution.</Li>
                <Li>In relation to sales and purchases between users through the services provided by the company, the existence and
                    authenticity of the intention to sell or purchase, the quality, completeness, stability, suitability of registered
                    goods, non-infringement of the rights of others, and published data We do not guarantee anything, such as the
                    truthfulness of, and all risks and responsibilities related to this must be borne by the user.</Li>
                <Li>The company does not control or restrict all information such as product descriptions posted by “users”. However, if it
                    is determined that the content of the information posted by the “user” infringes on the honor and rights of others, or
                    violates the rules, approval may be refused, deleted, and other necessary measures may be taken.</Li>
            </ol>
            <H5>Article 28 (Jurisdiction and Governing Law)</H5>
            <ol>
                <Li>Lawsuits filed between the company and “users” are governed by the laws of the Republic of Korea.</Li>
                <Li>Litigation regarding disputes between the company and “users” shall be resolved through consultation based on mutual
                    good faith, but if mutual consultation is difficult, the case shall be filed with the court having jurisdiction over the
                    location of the company having jurisdiction under the Civil Procedure Act.</Li>
            </ol>
            <P style={{marginTop: "40px"}}>
                Addendum<br/>
                Article 1 (Effective Date) These Terms and Conditions are effective from April 1, 2023.
            </P>
            <div style={{marginBottom: "180px"}}/>
        </ContentContainer>
    );
};

export default TermsOfServiceEnFragment;